.admin-courses-new-course-cover {
  width: 30%;
}
@media screen and (max-width: 800px) {
  .admin-courses-new-course-cover {
    width: 40%;
  }
}
@media screen and (max-width: 600px) {
  .admin-courses-new-course-cover {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .admin-courses-new-course-cover {
    width: 70%;
  }
}
