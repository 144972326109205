.dashboard-lesson-block-interactive-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-interactive-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-interactive-question-slot,
.dashboard-lesson-block-interactive-question-slot-option {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-interactive-question-match-slot {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: fit-content;
    min-width: 64px;
    height: 100%;
    min-height: 32px;
    padding: 0 8px;
    background-color: #ffffff;
    border: 1px dashed #eaeaea;
    border-radius: 4px;
}
.dashboard-lesson-block-interactive-question-match-slot.active {
    border-color: #0073e6 !important;
}
.dashboard-lesson-block-interactive-question-match-slot:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.dashboard-lesson-block-interactive-question-select-words:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words span.selected {
    background-color: #000;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-select-words span.selected,
.dashboard-lesson-block-interactive-question-select-words span.correct,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    padding: 2px;
    border-radius: 2px;
}
.dashboard-lesson-block-interactive-question-slot.correct,
.dashboard-lesson-block-interactive-question-slot-option.correct,
.dashboard-lesson-block-interactive-question-group-element.correct,
.dashboard-lesson-block-interactive-question-match-slot.correct,
.dashboard-lesson-block-interactive-question-select-words span.correct {
    background-color: #338e6b !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-slot.incorrect,
.dashboard-lesson-block-interactive-question-slot-option.incorrect,
.dashboard-lesson-block-interactive-question-group-element.incorrect,
.dashboard-lesson-block-interactive-question-match-slot.incorrect,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    background-color: #ea4643 !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-controls-container {
    margin-top: 8px;
}
.dashboard-lesson-block-interactive-question-hint {
    margin-top: 16px;
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-hint-button {
    margin-bottom: 16px !important;
}
.dashboard-lesson-block-interactive-question-hint-text {
    margin: 0;
    padding: 20px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-interactive-question-timer {
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-interactive-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 12px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 10px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
}
