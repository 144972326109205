.dashboard-lesson-block-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-question-header {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.dashboard-lesson-block-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-question-slot {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-question-hint {
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-question-hint-button {
    margin-bottom: 32px !important;
}
.dashboard-lesson-block-question-hint-text {
    margin: 0px;
    padding: 24px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-question-form {
    margin: 32px 0px;
    padding: 48px 72px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
}
.dashboard-lesson-block-question-form-instruction {
    font-size: 20px;
    margin-bottom: 32px;
}
.dashboard-lesson-block-question-form-option {
    display: flex;
    flex-direction: row;
}
.dashboard-lesson-block-question-form-checkbox,
.dashboard-lesson-block-question-form-radio {
    margin-right: 8px;
    font-size: 20px !important;
    font-style: italic !important;
}
.dashboard-lesson-block-question-form-time {
    font-size: 20px;
}
.dashboard-lesson-block-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}
.dashboard-lesson-block-question-explanation-indefinite {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid slategray;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 24px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 24px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 24px 0px;
        padding: 32px 64px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 16px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 16px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 16px 0px;
        padding: 24px 40px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-question {
        padding: 0px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 8px;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-question-form {
        margin: 12px 0px;
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 16px;
        font-size: 14px;
    }
}
