body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.admin-wrapper {
  padding: 52px 0 40px 0;
  min-height: 100vh;
}
.admin-header {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  background-color: #2d2d2a;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.7);
}
.admin-header nav {
  display: flex;
}
.admin-header-mobile {
  display: none;
}
.admin-header-mobile-button {
  display: none;
}
.admin-nav-link {
  cursor: pointer;
  padding: 16px 24px;
  color: #fffffa;
}
.admin-nav-link:hover {
  color: #95bddb;
}
.admin-nav-link-logout {
  margin-left: auto;
}
.admin-nav-link-active {
  background-color: #1a4f66;
}
.admin-content-wrapper {
  margin-top: 48px;

  & > .dashboard {
    margin-top: -64px;
  }
}
@media screen and (max-width: 800px) {
  .admin-wrapper {
    padding: 48px 16px;
  }
  .admin-header {
    display: none;
  }
  .admin-header-mobile {
    display: inherit;
  }
  .admin-header-mobile-button {
    position: fixed;
    z-index: 100;
    right: 24px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    cursor: pointer;
  }
  .admin-header-mobile-button img {
    width: 32px;
    height: 24px;
  }
  .admin-header-sidebar {
    width: 300px !important;
    background-color: #222222 !important;
    color: #fff;
  }
  .admin-nav-link {
    font-size: 16px;
  }
  .admin-content-wrapper {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .admin-header-sidebar {
    width: 250px !important;
  }
  .admin-nav-link {
    font-size: 14px;
  }
  .admin-content-wrapper {
    margin-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .admin-wrapper {
    padding: 40px 8px;
  }
  .admin-header-sidebar {
    width: 200px !important;
  }
  .admin-nav-link {
    font-size: 12px;
  }
  .admin-content-wrapper {
    margin-top: 0;
  }
}

.admin-analytics-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-analytics-class-subheader {
  margin-bottom: 1em;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.admin-analytics-search {
  margin: 2em auto;
}
.admin-analytics-class-search {
  margin-bottom: 2em;
}
@media screen and (max-width: 600px) {
  .admin-analytics-subheader {
    font-size: 16px;
  }
  .admin-analytics-class-subheader {
    font-size: 14px;
  }
}

.admin-authors-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-authors-new-author {
  margin: 2em 0 0.6em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-authors-subheader {
    font-size: 16px;
  }
  .admin-authors-new-author {
    margin: 1em 0 0.4em 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 400px) {
}

.admin-author-container {
  font-size: 15px;
}
.admin-author-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-author-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-author-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-author-header {
    font-size: 20px;
  }
  .admin-author-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-courses-header {
  font-size: 22px;
  text-align: center;
}
.admin-courses-new-course {
  margin: 2em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
.admin-courses-class-search{margin:2rem 0}
.admin-courses-card-header {
  font-size: 18px;
}
.admin-courses-card-description {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-courses-header {
    font-size: 16px;
  }
  .admin-courses-new-course {
    margin: 1em 0 !important;
    font-size: 14px !important;
  }
  .admin-courses-card-header {
    font-size: 14px !important;
  }
  .admin-courses-card-description {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-courses-new-course-cover {
  width: 30%;
}
@media screen and (max-width: 800px) {
  .admin-courses-new-course-cover {
    width: 40%;
  }
}
@media screen and (max-width: 600px) {
  .admin-courses-new-course-cover {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .admin-courses-new-course-cover {
    width: 70%;
  }
}

.admin-block-question-form-option {
  display: flex;
  flex-direction: row;
}

.admin-block-question-delete-option {
  cursor: pointer;
  opacity: 0.5;
}

.admin-block-question-delete-option:hover {
  opacity: 1;
}

.dashboard-lesson-block-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-question-header {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.dashboard-lesson-block-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-question-slot {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-question-hint {
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-question-hint-button {
    margin-bottom: 32px !important;
}
.dashboard-lesson-block-question-hint-text {
    margin: 0px;
    padding: 24px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-question-form {
    margin: 32px 0px;
    padding: 48px 72px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
}
.dashboard-lesson-block-question-form-instruction {
    font-size: 20px;
    margin-bottom: 32px;
}
.dashboard-lesson-block-question-form-option {
    display: flex;
    flex-direction: row;
}
.dashboard-lesson-block-question-form-checkbox,
.dashboard-lesson-block-question-form-radio {
    margin-right: 8px;
    font-size: 20px !important;
    font-style: italic !important;
}
.dashboard-lesson-block-question-form-time {
    font-size: 20px;
}
.dashboard-lesson-block-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}
.dashboard-lesson-block-question-explanation-indefinite {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid slategray;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 24px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 24px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 24px 0px;
        padding: 32px 64px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 16px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 16px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 16px 0px;
        padding: 24px 40px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-question {
        padding: 0px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 8px;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-question-form {
        margin: 12px 0px;
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.ql-editor blockquote {
    padding: .1em 2rem;
    border-left: 8px solid rgb(65, 148, 136);
    line-height: 1.6;
    position: relative;
    background: #fafafa;
}

.tooltip {
    position: relative;
    display: inline-block;
    background: antiquewhite;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 95%;
    cursor: help;
}
  
/* Tooltip text */
.tooltip > span {
    visibility: hidden;
    width: -webkit-max-content;
    width: max-content;
    max-width: 500px;
    font-size: 16px;
    background-color: #3791d4;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 1;
}

.tooltip[flow^="left"] > span {
    left: 0;
}
.tooltip[flow^="right"] > span {
    right: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover > span {
    visibility: visible;
}

.dashboard-lesson-block-interactive-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-interactive-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-interactive-question-slot,
.dashboard-lesson-block-interactive-question-slot-option {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-interactive-question-match-slot {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 64px;
    height: 100%;
    min-height: 32px;
    padding: 0 8px;
    background-color: #ffffff;
    border: 1px dashed #eaeaea;
    border-radius: 4px;
}
.dashboard-lesson-block-interactive-question-match-slot.active {
    border-color: #0073e6 !important;
}
.dashboard-lesson-block-interactive-question-match-slot:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.dashboard-lesson-block-interactive-question-select-words:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words span.selected {
    background-color: #000;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-select-words span.selected,
.dashboard-lesson-block-interactive-question-select-words span.correct,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    padding: 2px;
    border-radius: 2px;
}
.dashboard-lesson-block-interactive-question-slot.correct,
.dashboard-lesson-block-interactive-question-slot-option.correct,
.dashboard-lesson-block-interactive-question-group-element.correct,
.dashboard-lesson-block-interactive-question-match-slot.correct,
.dashboard-lesson-block-interactive-question-select-words span.correct {
    background-color: #338e6b !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-slot.incorrect,
.dashboard-lesson-block-interactive-question-slot-option.incorrect,
.dashboard-lesson-block-interactive-question-group-element.incorrect,
.dashboard-lesson-block-interactive-question-match-slot.incorrect,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    background-color: #ea4643 !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-controls-container {
    margin-top: 8px;
}
.dashboard-lesson-block-interactive-question-hint {
    margin-top: 16px;
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-hint-button {
    margin-bottom: 16px !important;
}
.dashboard-lesson-block-interactive-question-hint-text {
    margin: 0;
    padding: 20px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-interactive-question-timer {
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-interactive-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 12px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 10px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.admin-user-container {
  font-size: 15px;
}
.admin-user-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-user-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-user-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-user-header {
    font-size: 20px;
  }
  .admin-user-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-teachers-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-teachers-new-teacher {
  margin: 2em 0 0.6em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-teachers-subheader {
    font-size: 16px;
  }
  .admin-teachers-new-teacher {
    margin: 1em 0 0.4em 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 400px) {
}

.admin-teacher-container {
  font-size: 15px;
}
.admin-teacher-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-teacher-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-teacher-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-teacher-header {
    font-size: 20px;
  }
  .admin-teacher-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.dashboard {
  display: grid;
  grid-template-areas: 'course-navigation content menu-navigation';
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 10fr 2fr;
  min-height: 100vh;
}
.dashboard .course-navigation {
  display: flex;
  justify-content: flex-start;
  grid-area: course-navigation;
}
.dashboard .content {
  grid-area: content;
  padding: 72px 24px;
  width: 100%;
}
.dashboard .menu-navigation {
  display: flex;
  justify-content: flex-end;
  grid-area: menu-navigation;
}
.dashboard .mobile-navigation {
  display: none;
}
.dashboard h1 {
  margin-bottom: 0.6em;
  color: #000;
  font-family: inherit;
  font-size: 36px;
  line-height: 48px;
}
.dashboard h2 {
  margin-bottom: 1.4em;
  color: #000;
  font-family: inherit;
  font-size: 32px;
  line-height: 32px;
}

@media print {
  .dashboard .course-navigation {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .dashboard .content {
    padding: 64px 16px;
  }
  .dashboard h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .dashboard h2 {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .dashboard {
    display: flex;
  }
  .dashboard .course-navigation,
  .dashboard .menu-navigation {
    display: none;
  }
  .dashboard .content {
    padding: 48px 32px 80px 32px;
  }
  .dashboard .mobile-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0px;
    width: 100%;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.75);
    border-top: 2px solid #4682e0;
  }
  .dashboard .mobile-navigation #print-button {
    display: none;
  }
  .dashboard h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .dashboard h2 {
    font-size: 22px;
    line-height: 22px;
  }
}
@media screen and (max-width: 400px) {
  .dashboard .content {
    padding: 32px 24px 72px 24px;
  }
  .dashboard h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .dashboard h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .dashboard .mobile-navigation {
    height: 56px;
  }
}

[data-name] {
    transition: all .3s ease;
}

[data-name].active {
    position: relative;
}

[data-name].active::before {
    content: '';
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #4682E0;
}

[data-name] {
    transition: all .3s ease;
}

[data-name].active {
    position: relative;
}

[data-name].active::before {
    content: '';
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #4682E0;
}

.filter_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 1.5rem;
}

.filter_wrapper .select_wrapper {
    position: relative;
    display: flex;
    width: 256px;
}

.filter_wrapper .select_wrapper .select_title {
    position: relative;
    background: #fff;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.filter_wrapper .select_wrapper .select_title svg {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: .75rem;
    transition: -webkit-transform .25s ease;
    transition: transform .25s ease;
    transition: transform .25s ease, -webkit-transform .25s ease;
}

.filter_wrapper .select_wrapper .hidden {
    display: none;
}

.filter_wrapper .select_wrapper .peer ~ .option_wrapper {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #96c8da;
    z-index: 1;
    background: #fff;
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    padding: .25rem 0;
    width: 100%;
    max-height: 356px;
    overflow-y: auto;
}

.filter_wrapper .select_wrapper .peer:checked ~ .option_wrapper {
    display: flex;
}

.filter_wrapper .select_wrapper .peer:checked ~ .select_title svg {
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

.filter_wrapper .select_wrapper .option_wrapper label {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
}

.filter_wrapper .select_wrapper .option_wrapper label span {
    margin-left: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter_wrapper .select_wrapper .option_wrapper label span::after {
    content: '';
    position: absolute;
    inset: 0;
}

.filter_wrapper .select_wrapper .option_wrapper label:hover span::after {
    background: rgba(0,0,0,.05);
}

.filter_wrapper .select_wrapper .option_wrapper label input:checked ~ span::after {
    background: rgba(0,0,0,.03);
}

.search_wrapper,
.tags_wrapper {
    width: calc(256px * 3 + 16px * 2);
}

.tags_wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.tags_wrapper label {
    display: flex;
    position: relative;
    background: rgba(0,0,0,.03);
    border-radius: 999px;
    padding: .25rem 2.1rem .25rem 0.75rem;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 90%;
    transition: all .25s ease;
    align-self: center;
}
.tags_wrapper label:hover {
    background: rgba(0,0,0,.05);
}
.tags_wrapper label::after {
    content: '\D7';
    background: rgba(0,0,0,.05);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: .25rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 999px;
    font-weight: 400;
}
.tags_wrapper label:hover .tags_wrapper label::after {
    background: rgba(0,0,0,.07);
}

@media (min-width: 600px) and (max-width: 800px) {
    .filter_wrapper .select_wrapper {
        width: 196px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(196px * 3 + 14px * 2);
    }
}
@media (min-width: 400px) and (max-width: 600px) {
    .filter_wrapper {
        grid-gap: 8px;
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 160px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(160px * 3 + 8px * 2);
    }
}

@media (max-width: 400px) {
    .filter_wrapper {
        grid-gap: 8px;
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 144px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(144px * 3 + 14px * 2);
    }
}
.dashboard-lesson-footer-wrapper {
    background: #f2f2f2;
    /* margin: 0px 16px 40px 16px; */
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-lesson-footer-header {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    /* text-transform: uppercase; */
}
.dashboard-lesson-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-lesson-footer-stats-right-text,
.dashboard-lesson-footer-stats-total-text,
.dashboard-lesson-footer-stats-time-text {
    text-transform: lowercase;
}
.dashboard-lesson-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-lesson-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-lesson-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-lesson-footer-stats-right-number,
.dashboard-lesson-footer-stats-total-number,
.dashboard-lesson-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-lesson-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-lesson-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-lesson-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-lesson-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-lesson-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-lesson-footer-questions-correct {
    color: #198c19;
}
.dashboard-lesson-footer-questions-wrong {
    color: #cc0000;
}
@media only screen and (max-width: 800px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-lesson-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-materials-container {
    font-size: 14px;
    color: #000;
}
.dashboard-materials-container-header {
    margin-bottom: 2em;
    font-size: 22px;
    text-align: center;
}
.dashboard-materials-container-lesson {
    padding: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-materials-container-lesson-header {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 400;
}
@media screen and (max-width: 800px) {
    .dashboard-materials-container {
        padding: 0 40px;
    }
    .dashboard-materials-container-header {
        font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-materials-container {
        padding: 0 24px;
        font-size: 12px;
    }
    .dashboard-materials-container-header {
        font-size: 18px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-materials-container {
        font-size: 10px;
    }
    .dashboard-materials-container-header {
        font-size: 16px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 12px;
    }
}

.dashboard-practice-forbidden,
.dashboard-practice-not-found {
    font-size: 20px;
}
.dashboard-practice-forbidden-image {
    padding-top: 24px;
    width: 50%;
}
.dashboard-practice-not-found-image {
    width: 60%;
}
.dashboard-practice-name {
    margin-bottom: 2em;
    padding: 0 72px;
    font-size: 36px;
    line-height: 1.2;
    text-align: left;
}
.dashboard-practice-name-id {
    margin-right: 1em;
}
.dashboard-practice-container {
    font-size: 18px;
    font-weight: 300;
}
.dashboard-practice-question {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-practice-question p {
    line-height: 1.4;
}
.dashboard-practice-stars {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 2em;
}
.dashboard-practice-star {
    margin: 0 16px;
    width: 48px;
    height: 48px;
    opacity: 1;
    -webkit-animation-name: star-animation;
    -webkit-animation-duration: 4s;
    animation-name: star-animation;
    animation-duration: 4s;
}

@-webkit-keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dashboard-practice-next-question-button {
    margin-left: 120px;
}
.dashboard-practice-next-lesson {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}
.dashboard-practice-next-lesson-link,
.dashboard-practice-next-lesson-link-inactive {
    cursor: pointer;
    color: #000;
    font-size: 32px;
    line-height: 1.2;
    margin: 0px 16px;
    text-align: center;
    text-decoration: none;
    /* text-transform: lowercase; */
}
.dashboard-practice-next-lesson-link:hover {
    color: slategrey;
}
.dashboard-practice-next-lesson-link-inactive {
    cursor: default;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 18px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 2em;
        width: 60%;
    }
    .dashboard-practice-name {
        font-size: 32px;
        line-height: 1.6;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 16px;
    }
    .dashboard-practice-question {
        margin-bottom: 3em;
    }
    .dashboard-practice-question p {
        line-height: 1.4;
    }
    .dashboard-practice-stars {
        margin-bottom: 3em;
    }
    .dashboard-practice-star {
        margin: 0 24px;
        width: 40px;
        height: 40px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 72px;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 24px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 16px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 64px;
        font-size: 28px;
        line-height: 1.2;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 14px;
    }
    .dashboard-practice-question {
        margin-bottom: 2em;
    }
    .dashboard-practice-question p {
        line-height: 1.2;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 16px;
        width: 32px;
        height: 32px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 3em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 2em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 22px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 14px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 40px;
        font-size: 24px;
        line-height: 1.1;
    }
    .dashboard-practice-name-id {
        margin-right: 0.6em;
    }
    .dashboard-practice-container {
        font-size: 10px;
    }
    .dashboard-practice-question {
        margin-bottom: 1.6em;
    }
    .dashboard-practice-question p {
        line-height: 1.1;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 8px;
        width: 24px;
        height: 24px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 2em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 18px;
    }
}

.dashboard-practice-footer-wrapper {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-footer-header {
    margin-bottom: 24px;
    font-size: 20px;
    text-transform: uppercase;
}
.dashboard-practice-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-practice-footer-stats-right-text,
.dashboard-practice-footer-stats-total-text,
.dashboard-practice-footer-stats-time-text {
    /* text-transform: lowercase; */
}
.dashboard-practice-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-practice-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-practice-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-practice-footer-stats-right-number,
.dashboard-practice-footer-stats-total-number,
.dashboard-practice-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-practice-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-practice-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-practice-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-practice-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-practice-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-practice-footer-questions-correct {
    color: #198c19;
}
.dashboard-practice-footer-questions-wrong {
    color: #cc0000;
}
.dashboard-practice-footer-extra {
    text-align: left;
}
.dashboard-practice-footer-extra-header {
    margin-bottom: 1em;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-practice-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-practice-header-stats {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 80px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-header-stats-header {
    font-size: 20px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "subheader subheader"
        "rightText totalText"
        "rightNumber totalNumber";
}
.dashboard-practice-header-stats-results-subheader {
    grid-area: subheader;
    font-size: 18px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results-right-text {
    grid-area: rightText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-total-text {
    grid-area: totalText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-right-number {
    grid-area: rightNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
.dashboard-practice-header-stats-results-total-number {
    grid-area: totalNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-header-stats {
        margin: 0px 24px 32px 24px;
        padding: 16px 40px;
        font-size: 12px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 18px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 18px;
        line-height: 1;
    }
}

.dashboard-practice-question-header {
    margin-bottom: 32px;
    padding: 0px 120px;
    font-size: 22px;
    /* text-transform: uppercase; */
    /* font-weight: 500; */
}
.dashboard-practice-question-text {
    margin-bottom: 32px;
    padding: 0 120px;
    font-size: 20px;
}
.dashboard-practice-question-image {
    margin-bottom: 32px;
    padding: 0 32px;
}
.dashboard-practice-question-hint {
    padding: 0 120px;
}
.dashboard-practice-question-hint-text {
    padding: 24px 48px;
    border-left: 1px solid #000;
}
.dashboard-practice-question-form {
    margin: 32px 24px;
    padding: 40px 64px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
    font-size: 18px;
}
.dashboard-practice-question-form-instruction {
    font-size: 18px;
    margin-bottom: 24px;
}
.dashboard-practice-question-explanation-correct {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #2cd973;
}
.dashboard-practice-question-explanation-wrong {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #ec0042;
}
.dashboard-practice-question-explanation {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid slategray;
}
.dashboard-practice-question-explanation-correct,
.dashboard-practice-question-explanation-wrong,
.dashboard-practice-question-explanation {
    font-size: 18px;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-question-header {
        padding: 0px 80px;
        font-size: 22px;
    }
    .dashboard-practice-question-text {
        padding: 0 80px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 80px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 48px;
        padding: 32px 80px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-question-header {
        padding: 0px 64px;
        font-size: 20px;
    }
    .dashboard-practice-question-text {
        padding: 0 64px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 64px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 40px;
        padding: 32px 64px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-question-header {
        padding: 0px 40px;
        font-size: 18px;
    }
    .dashboard-practice-question-text {
        padding: 0 40px;
        font-size: 16px;
    }
    .dashboard-practice-question-image {
        padding: 0 16px;
    }
    .dashboard-practice-question-hint {
        padding: 0 40px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 14px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 16px 24px;
        padding: 32px 40px;
        font-size: 12px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 16px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 24px;
        font-size: 14px;
    }
}

.dashboard-practice-practices-list-group {
    margin-bottom: 1.6em;
}
.dashboard-practice-practices-list-group-name {
    margin-bottom: 1em;
    font-size: 18px;
    font-weight: 500;
}
.dashboard-practice-practices-list-block {
    margin-bottom: 1em;
    font-size: 16px;
}
.dashboard-practice-practices-list-link {
    color: #000;
}
.dashboard-practice-practices-list-link:hover {
    color: #646464;
    border-bottom: 1px solid #646464;
}
@media screen and (max-width: 800px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 16px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

.landing_page *,
.landing_page ::before,
.landing_page ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.landing_page ::before,
.landing_page ::after {
  --tw-content: '';
}

.landing_page html,
.landing_page :host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ProximaNova, sans-serif;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

.landing_page body {
  margin: 0;
  line-height: inherit;
}

.landing_page hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

.landing_page abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.landing_page h1,
.landing_page h2,
.landing_page h3,
.landing_page h4,
.landing_page h5,
.landing_page h6 {
  font-size: inherit;
  font-weight: inherit;
}

.landing_page a {
  color: inherit;
  text-decoration: inherit;
}

.landing_page b,
.landing_page strong {
  font-weight: bolder;
}

.landing_page code,
.landing_page kbd,
.landing_page samp,
.landing_page pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}

.landing_page small {
  font-size: 80%;
}

.landing_page sub,
.landing_page sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.landing_page sub {
  bottom: -0.25em;
}

.landing_page sup {
  top: -0.5em;
}

.landing_page table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.landing_page button,
.landing_page input,
.landing_page optgroup,
.landing_page select,
.landing_page textarea {
  font-family: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.landing_page button,
.landing_page select {
  text-transform: none;
}

.landing_page button,
.landing_page input:where([type='button']),
.landing_page input:where([type='reset']),
.landing_page input:where([type='submit']) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.landing_page :-moz-focusring {
  outline: auto;
}

.landing_page :-moz-ui-invalid {
  box-shadow: none;
}

.landing_page progress {
  vertical-align: baseline;
}

.landing_page ::-webkit-inner-spin-button,
.landing_page ::-webkit-outer-spin-button {
  height: auto;
}

.landing_page [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.landing_page ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.landing_page ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.landing_page summary {
  display: list-item;
}

.landing_page blockquote,
.landing_page dl,
.landing_page dd,
.landing_page h1,
.landing_page h2,
.landing_page h3,
.landing_page h4,
.landing_page h5,
.landing_page h6,
.landing_page hr,
.landing_page figure,
.landing_page p,
.landing_page pre {
  margin: 0;
}

.landing_page fieldset {
  margin: 0;
  padding: 0;
}

.landing_page legend {
  padding: 0;
}

.landing_page ol,
.landing_page ul,
.landing_page menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.landing_page dialog {
  padding: 0;
}

.landing_page textarea {
  resize: vertical;
}

.landing_page input::-webkit-input-placeholder, .landing_page textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

.landing_page input::placeholder,
.landing_page textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.landing_page button,
.landing_page [role="button"] {
  cursor: pointer;
}

.landing_page :disabled {
  cursor: default;
}

.landing_page img,
.landing_page svg,
.landing_page video,
.landing_page canvas,
.landing_page audio,
.landing_page iframe,
.landing_page embed,
.landing_page object {
  display: block;
  vertical-align: middle;
}

.landing_page img,
.landing_page video {
  max-width: 100%;
  height: auto;
}

.landing_page [hidden] {
  display: none;
}

/* width */

.landing_page ::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  overflow: hidden;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.landing_page ::-webkit-scrollbar-track {
  background-color: rgb(15 23 42 / 0.1);
}

.landing_page ::-webkit-scrollbar-thumb {
  background-color: rgb(15 23 42 / 0.2);
}

.landing_page ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(15 23 42 / 0.3);
}

.landing_page html,
.landing_page body {
  height: 100%;
  width: 100%;
  font-family: "ProximaNova";
}

.landing_page *,
.landing_page ::before,
.landing_page ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.landing_page ::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.landing_page ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.landing_page .e-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.landing_page .e-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.landing_page .e-prose :where(p):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where([class~="lead"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.landing_page .e-prose :where(a):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.landing_page .e-prose :where(strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.landing_page .e-prose :where(a strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(blockquote strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(thead th strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(ol):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  -webkit-padding-start: 1.625em;
          padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(ol[type="A"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.landing_page .e-prose :where(ol[type="a"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.landing_page .e-prose :where(ol[type="A" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.landing_page .e-prose :where(ol[type="a" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.landing_page .e-prose :where(ol[type="I"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-roman;
}

.landing_page .e-prose :where(ol[type="i"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-roman;
}

.landing_page .e-prose :where(ol[type="I" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-roman;
}

.landing_page .e-prose :where(ol[type="i" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-roman;
}

.landing_page .e-prose :where(ol[type="1"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: decimal;
}

.landing_page .e-prose :where(ul):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  -webkit-padding-start: 1.625em;
          padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(ol > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.landing_page .e-prose :where(ul > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.landing_page .e-prose :where(dt):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}

.landing_page .e-prose :where(hr):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.landing_page .e-prose :where(blockquote):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-inline-start-width: 0.25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "\201C" "\201D" "\2018" "\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
}

.landing_page .e-prose :where(blockquote p:first-of-type):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: open-quote;
}

.landing_page .e-prose :where(blockquote p:last-of-type):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: close-quote;
}

.landing_page .e-prose :where(h1):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.landing_page .e-prose :where(h1 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}

.landing_page .e-prose :where(h2):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.landing_page .e-prose :where(h2 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}

.landing_page .e-prose :where(h3):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.landing_page .e-prose :where(h3 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.landing_page .e-prose :where(h4):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.landing_page .e-prose :where(h4 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.landing_page .e-prose :where(img):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(picture):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(video):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(kbd):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  -webkit-padding-end: 0.375em;
          padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  -webkit-padding-start: 0.375em;
          padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: "`";
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: "`";
}

.landing_page .e-prose :where(a code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(h1 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(h2 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}

.landing_page .e-prose :where(h3 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}

.landing_page .e-prose :where(h4 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(blockquote code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(thead th code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(pre):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  -webkit-padding-end: 1.1428571em;
          padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  -webkit-padding-start: 1.1428571em;
          padding-inline-start: 1.1428571em;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: none;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: none;
}

.landing_page .e-prose :where(table):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.landing_page .e-prose :where(thead):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.landing_page .e-prose :where(thead th):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  -webkit-padding-end: 0.5714286em;
          padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  -webkit-padding-start: 0.5714286em;
          padding-inline-start: 0.5714286em;
}

.landing_page .e-prose :where(tbody tr):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.landing_page .e-prose :where(tbody tr:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 0;
}

.landing_page .e-prose :where(tbody td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  vertical-align: baseline;
}

.landing_page .e-prose :where(tfoot):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.landing_page .e-prose :where(tfoot td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  vertical-align: top;
}

.landing_page .e-prose :where(th, td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  text-align: start;
}

.landing_page .e-prose :where(figure > *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.landing_page .e-prose :where(figcaption):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.landing_page .e-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.landing_page .e-prose :where(picture > img):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.landing_page .e-prose :where(li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.landing_page .e-prose :where(ol > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-start: 0.375em;
          padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(ul > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-start: 0.375em;
          padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(.e-prose > ul > li p):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.landing_page .e-prose :where(.e-prose > ul > li > p:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ul > li > p:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ol > li > p:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ol > li > p:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.landing_page .e-prose :where(dl):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(dd):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.5em;
  -webkit-padding-start: 1.625em;
          padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(hr + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h2 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h3 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h4 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(thead th:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.landing_page .e-prose :where(thead th:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

.landing_page .e-prose :where(tbody td, tfoot td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-top: 0.5714286em;
  -webkit-padding-end: 0.5714286em;
          padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  -webkit-padding-start: 0.5714286em;
          padding-inline-start: 0.5714286em;
}

.landing_page .e-prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.landing_page .e-prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

.landing_page .e-prose :where(figure):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(.e-prose > :first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(.e-prose > :last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 0;
}

.landing_page .e-container {
  max-width: 80rem;
}

.landing_page .e-font-logo {
  font-family: "Carter One";
}

.landing_page .e-font-roboto {
  font-family: "Roboto";
}

.landing_page .e-btn-xs {
  height: 2rem !important;
}

.landing_page .e-btn {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;
  transition: -webkit-transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.landing_page .e-btn-lg {
  height: 3.5rem;
}

.landing_page .e-btn-primary {
  background: linear-gradient(to top, #0484FF, #4FA7F7);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-btn-primary:hover {
  background: linear-gradient(to top right, #0484FF, #4FA7F7);
}

.landing_page .e-btn-light {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-btn-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.landing_page .e-btn-secondary {
  background-image: linear-gradient(to right, #FFA602, #FFCE70);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-btn-secondary:hover {
  background-image: linear-gradient(to top right, #FFA602, #FFCE70);
}

.landing_page .e-bg-custom-gradient {
  background: linear-gradient(to bottom right, #FFFCF099 0%, #FFFCF099 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) 100%);
}

.landing_page .e-bg-custom-gradient-primary {
  background: linear-gradient(to bottom right, #117EE410 0%, #117EE410 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) 100%);
}

.landing_page .e-bg-gradient-sky {
  background-image: linear-gradient(to top right, #e0f2fe, #f0f9ff);
}

.landing_page .e-bg-gradient-amber {
  background-image: linear-gradient(to top right, #fef3c7, #fffbeb);
}

.landing_page .e-bg-gradient-purple {
  background-image: linear-gradient(to top right, #f3e8ff, #faf5ff);
}

.landing_page .e-bg-gradient-rose {
  background-image: linear-gradient(to top right, #ffe4e6, #fff1f2);
}

.landing_page .e-card {
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.landing_page .e-card-content {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.landing_page .e-card:hover .landing_page .e-card-content {
  -webkit-transform: rotateY(15deg) rotateX(10deg);
          transform: rotateY(15deg) rotateX(10deg);
}

.landing_page .e-titlemark {
  position: relative;
}

.landing_page .e-titlemark::before {
  position: absolute;
  right: -1rem;
  top: -0.125rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  --tw-rotate: 75deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url(/static/media/titlemark.94634773.svg);
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.landing_page .e-graph-paper::before {
  content: '';
  z-index: -1;
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.landing_page .e-swiper-prev,
.landing_page .e-swiper-next {
  display: flex;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #0484FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(4 132 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #4FA7F7 var(--tw-gradient-to-position);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-swiper-prev:hover,
.landing_page .e-swiper-next:hover {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.landing_page .e-swiper-prev,
.landing_page .e-swiper-next {
  box-shadow: inset 0px 0px 0px 2px transparent;
}

.landing_page .e-swiper-prev.swiper-button-disabled,
.landing_page .e-swiper-next.swiper-button-disabled {
  cursor: not-allowed;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
  color: inherit;
  box-shadow: inset 0px 0px 0px 2px #e5e7eb;
}

.landing_page .e-accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
}

.landing_page .e-accordion-content.e-show {
  opacity: 1;
}

.landing_page .e-modal {
  visibility: hidden;
  position: fixed;
  inset: 0px;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  background-color: #0006;
}

.landing_page .e-modal.e-show {
  visibility: visible;
  opacity: 1;
}

.landing_page .e-modal .e-modal_card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-direction: column;
  overflow: auto;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  max-height: calc(100vh - 80px);
  border: .5px solid #e1e6f0;
}

.landing_page .e-modal.e-show .e-modal_card {
  --tw-translate-y: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .ui.selection.dropdown {
  position: relative;
}

.landing_page .ui.selection.dropdown>.text {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;
  transition: -webkit-transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .ui.selection.dropdown>.text:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.landing_page .ui.selection.dropdown>.text {
  cursor: pointer;
}

.landing_page .ui.selection.dropdown>.menu {
  position: absolute;
  right: 0px;
  margin-top: 0.25rem;
  display: none;
  width: 14rem;
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .ui.selection.dropdown>.menu.visible {
  display: flex;
  flex-direction: column;
}

.landing_page .ui.selection.dropdown>.menu .item {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .ui.selection.dropdown>.menu .item:hover {
  background-color: rgb(17 126 228 / 0.05);
}

.landing_page .ui.selection.dropdown .item.selected {
  position: relative;
}

.landing_page .ui.selection.dropdown .item.selected::before {
  position: absolute;
  right: 1rem;
  top: 50%;
  display: flex;
  height: 1rem;
  width: 1rem;
  content: var(--tw-content);
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='rgba(17,126,228,1)'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
}

.landing_page .e-fixed {
  position: fixed;
}

.landing_page .e-absolute {
  position: absolute;
}

.landing_page .e-relative {
  position: relative;
}

.landing_page .e-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.landing_page .e-inset-x-0 {
  left: 0px;
  right: 0px;
}

.landing_page .e-bottom-2 {
  bottom: 0.5rem;
}

.landing_page .e-left-0 {
  left: 0px;
}

.landing_page .e-right-0 {
  right: 0px;
}

.landing_page .e-right-2 {
  right: 0.5rem;
}

.landing_page .e-right-4 {
  right: 1rem;
}

.landing_page .e-right-px {
  right: 1px;
}

.landing_page .e-top-0 {
  top: 0px;
}

.landing_page .e-top-1\/2 {
  top: 50%;
}

.landing_page .e-top-4 {
  top: 1rem;
}

.landing_page .e-top-px {
  top: 1px;
}

.landing_page .e-z-10 {
  z-index: 10;
}

.landing_page .e-z-50 {
  z-index: 50;
}

.landing_page .e-col-span-2 {
  grid-column: span 2 / span 2;
}

.landing_page .e-col-span-full {
  grid-column: 1 / -1;
}

.landing_page .e-m-auto {
  margin: auto;
}

.landing_page .e-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.landing_page .e-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.landing_page .\!e-mb-4 {
  margin-bottom: 1rem !important;
}

.landing_page .-e-mt-1 {
  margin-top: -0.25rem;
}

.landing_page .e-mb-0 {
  margin-bottom: 0px;
}

.landing_page .e-mb-20 {
  margin-bottom: 5rem;
}

.landing_page .e-mb-4 {
  margin-bottom: 1rem;
}

.landing_page .e-mb-40 {
  margin-bottom: 10rem;
}

.landing_page .e-mb-6 {
  margin-bottom: 1.5rem;
}

.landing_page .e-mb-8 {
  margin-bottom: 2rem;
}

.landing_page .e-ml-1 {
  margin-left: 0.25rem;
}

.landing_page .e-ml-auto {
  margin-left: auto;
}

.landing_page .e-mr-2 {
  margin-right: 0.5rem;
}

.landing_page .e-mt-1 {
  margin-top: 0.25rem;
}

.landing_page .e-mt-10 {
  margin-top: 2.5rem;
}

.landing_page .e-mt-2 {
  margin-top: 0.5rem;
}

.landing_page .e-mt-4 {
  margin-top: 1rem;
}

.landing_page .e-flex {
  display: flex;
}

.landing_page .e-grid {
  display: grid;
}

.landing_page .e-hidden {
  display: none;
}

.landing_page .e-aspect-square {
  aspect-ratio: 1 / 1;
}

.landing_page .e-aspect-video {
  aspect-ratio: 16 / 9;
}

.landing_page .e-h-0\.5 {
  height: 0.125rem;
}

.landing_page .e-h-10 {
  height: 2.5rem;
}

.landing_page .e-h-12 {
  height: 3rem;
}

.landing_page .e-h-14 {
  height: 3.5rem;
}

.landing_page .e-h-16 {
  height: 4rem;
}

.landing_page .e-h-20 {
  height: 5rem;
}

.landing_page .e-h-3 {
  height: 0.75rem;
}

.landing_page .e-h-5 {
  height: 1.25rem;
}

.landing_page .e-h-6 {
  height: 1.5rem;
}

.landing_page .e-h-72 {
  height: 18rem;
}

.landing_page .e-h-\[1\.125rem\] {
  height: 1.125rem;
}

.landing_page .e-h-full {
  height: 100%;
}

.landing_page .e-h-screen {
  height: 100vh;
}

.landing_page .e-w-1\/3 {
  width: 33.333333%;
}

.landing_page .e-w-12 {
  width: 3rem;
}

.landing_page .e-w-14 {
  width: 3.5rem;
}

.landing_page .e-w-16 {
  width: 4rem;
}

.landing_page .e-w-2\/3 {
  width: 66.666667%;
}

.landing_page .e-w-20 {
  width: 5rem;
}

.landing_page .e-w-28 {
  width: 7rem;
}

.landing_page .e-w-3\/4 {
  width: 75%;
}

.landing_page .e-w-36 {
  width: 9rem;
}

.landing_page .e-w-4 {
  width: 1rem;
}

.landing_page .e-w-44 {
  width: 11rem;
}

.landing_page .e-w-48 {
  width: 12rem;
}

.landing_page .e-w-5 {
  width: 1.25rem;
}

.landing_page .e-w-5\/6 {
  width: 83.333333%;
}

.landing_page .e-w-56 {
  width: 14rem;
}

.landing_page .e-w-8 {
  width: 2rem;
}

.landing_page .e-w-80 {
  width: 20rem;
}

.landing_page .e-w-\[1\.125rem\] {
  width: 1.125rem;
}

.landing_page .e-w-fit {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

.landing_page .e-w-full {
  width: 100%;
}

.landing_page .e-max-w-3xl {
  max-width: 48rem;
}

.landing_page .e-max-w-md {
  max-width: 28rem;
}

.landing_page .e-flex-shrink-0 {
  flex-shrink: 0;
}

.landing_page .-e-translate-y-1\/2 {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .-e-translate-y-full {
  --tw-translate-y: -100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-translate-x-10 {
  --tw-translate-x: 2.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-translate-x-40 {
  --tw-translate-x: 10rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes e-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes e-pulse {
  50% {
    opacity: .5;
  }
}

.landing_page .e-animate-pulse {
  -webkit-animation: e-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: e-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.landing_page .e-cursor-pointer {
  cursor: pointer;
}

.landing_page .e-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.landing_page .e-resize-none {
  resize: none;
}

.landing_page .e-columns-3 {
  -webkit-columns: 3;
          columns: 3;
}

.landing_page .e-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.landing_page .e-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.landing_page .e-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.landing_page .e-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.landing_page .e-flex-col {
  flex-direction: column;
}

.landing_page .e-flex-wrap {
  flex-wrap: wrap;
}

.landing_page .e-items-start {
  align-items: flex-start;
}

.landing_page .e-items-end {
  align-items: flex-end;
}

.landing_page .e-items-center {
  align-items: center;
}

.landing_page .e-justify-end {
  justify-content: flex-end;
}

.landing_page .e-justify-center {
  justify-content: center;
}

.landing_page .e-justify-between {
  justify-content: space-between;
}

.landing_page .e-gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.landing_page .e-gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.landing_page .e-gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.landing_page .e-gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.landing_page .e-gap-x-10 {
  -moz-column-gap: 2.5rem;
  grid-column-gap: 2.5rem;
  -webkit-column-gap: 2.5rem;
          column-gap: 2.5rem;
}

.landing_page .e-gap-x-6 {
  -moz-column-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.landing_page .e-gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.landing_page .e-gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.landing_page .e-space-x-1\.5> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-10> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-2> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-3> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-4> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-6> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-y-1> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-10> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-2> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-3> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-6> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-overflow-auto {
  overflow: auto;
}

.landing_page .e-overflow-hidden {
  overflow: hidden;
}

.landing_page .e-rounded {
  border-radius: 0.25rem;
}

.landing_page .e-rounded-2xl {
  border-radius: 1rem;
}

.landing_page .e-rounded-3xl {
  border-radius: 1.5rem;
}

.landing_page .e-rounded-full {
  border-radius: 9999px;
}

.landing_page .e-rounded-lg {
  border-radius: 0.5rem;
}

.landing_page .e-rounded-md {
  border-radius: 0.375rem;
}

.landing_page .e-rounded-sm {
  border-radius: 0.125rem;
}

.landing_page .e-rounded-xl {
  border-radius: 0.75rem;
}

.landing_page .e-border {
  border-width: 1px;
}

.landing_page .e-border-2 {
  border-width: 2px;
}

.landing_page .e-border-b {
  border-bottom-width: 1px;
}

.landing_page .e-border-t {
  border-top-width: 1px;
}

.landing_page .e-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.landing_page .e-border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.landing_page .e-border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.landing_page .e-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .e-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.landing_page .e-bg-secondary\/20 {
  background-color: rgb(255 165 1 / 0.2);
}

.landing_page .e-bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.landing_page .e-bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.landing_page .e-bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.landing_page .e-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.landing_page .e-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.landing_page .e-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.landing_page .e-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.landing_page .e-from-amber-100 {
  --tw-gradient-from: #fef3c7 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-amber-400 {
  --tw-gradient-from: #fbbf24 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-purple-100 {
  --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-purple-400 {
  --tw-gradient-from: #c084fc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-rose-100 {
  --tw-gradient-from: #ffe4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-rose-400 {
  --tw-gradient-from: #fb7185 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-sky-100 {
  --tw-gradient-from: #e0f2fe var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-sky-400 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-to-amber-200 {
  --tw-gradient-to: #fde68a var(--tw-gradient-to-position);
}

.landing_page .e-to-amber-50 {
  --tw-gradient-to: #fffbeb var(--tw-gradient-to-position);
}

.landing_page .e-to-purple-200 {
  --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.landing_page .e-to-purple-50 {
  --tw-gradient-to: #faf5ff var(--tw-gradient-to-position);
}

.landing_page .e-to-rose-200 {
  --tw-gradient-to: #fecdd3 var(--tw-gradient-to-position);
}

.landing_page .e-to-rose-50 {
  --tw-gradient-to: #fff1f2 var(--tw-gradient-to-position);
}

.landing_page .e-to-sky-200 {
  --tw-gradient-to: #bae6fd var(--tw-gradient-to-position);
}

.landing_page .e-to-sky-50 {
  --tw-gradient-to: #f0f9ff var(--tw-gradient-to-position);
}

.landing_page .e-object-contain {
  object-fit: contain;
}

.landing_page .e-object-cover {
  object-fit: cover;
}

.landing_page .e-p-10 {
  padding: 2.5rem;
}

.landing_page .e-p-4 {
  padding: 1rem;
}

.landing_page .e-p-6 {
  padding: 1.5rem;
}

.landing_page .e-p-7 {
  padding: 1.75rem;
}

.landing_page .e-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.landing_page .e-px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.landing_page .e-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.landing_page .e-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.landing_page .e-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.landing_page .e-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.landing_page .e-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.landing_page .e-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.landing_page .e-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.landing_page .e-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.landing_page .e-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.landing_page .e-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.landing_page .e-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.landing_page .e-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.landing_page .e-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.landing_page .e-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.landing_page .e-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing_page .e-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.landing_page .e-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.landing_page .e-pb-16 {
  padding-bottom: 4rem;
}

.landing_page .e-pl-12 {
  padding-left: 3rem;
}

.landing_page .e-pr-20 {
  padding-right: 5rem;
}

.landing_page .e-pr-3 {
  padding-right: 0.75rem;
}

.landing_page .e-pt-0 {
  padding-top: 0px;
}

.landing_page .e-pt-36 {
  padding-top: 9rem;
}

.landing_page .e-pt-6 {
  padding-top: 1.5rem;
}

.landing_page .e-text-left {
  text-align: left;
}

.landing_page .e-text-center {
  text-align: center;
}

.landing_page .e-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.landing_page .e-text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.landing_page .e-text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.landing_page .e-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.landing_page .e-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.landing_page .e-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.landing_page .e-font-medium {
  font-weight: 500;
}

.landing_page .e-font-semibold {
  font-weight: 600;
}

.landing_page .e-leading-none {
  line-height: 1;
}

.landing_page .e-leading-tight {
  line-height: 1.25;
}

.landing_page .e-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.landing_page .e-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.landing_page .e-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.landing_page .e-text-primary {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

.landing_page .e-text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.landing_page .e-text-secondary {
  --tw-text-opacity: 1;
  color: rgb(255 165 1 / var(--tw-text-opacity));
}

.landing_page .e-text-secondary\/20 {
  color: rgb(255 165 1 / 0.2);
}

.landing_page .e-text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.landing_page .e-text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.landing_page .e-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.landing_page .e-opacity-0 {
  opacity: 0;
}

.landing_page .e-opacity-100 {
  opacity: 1;
}

.landing_page .e-opacity-5 {
  opacity: 0.05;
}

.landing_page .e-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-\[0_15px_20px_-25px_rgba\(0\,0\,0\,\.35\)\] {
  --tw-shadow: 0 15px 20px -25px rgba(0, 0, 0, .35);
  --tw-shadow-colored: 0 15px 20px -25px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-gray-400\/5 {
  --tw-shadow-color: rgb(156 163 175 / 0.05);
  --tw-shadow: var(--tw-shadow-colored);
}

.landing_page .e-shadow-secondary\/20 {
  --tw-shadow-color: rgb(255 165 1 / 0.2);
  --tw-shadow: var(--tw-shadow-colored);
}

.landing_page .e-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.landing_page .e-drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.landing_page .e-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-duration-300 {
  transition-duration: 300ms;
}

.landing_page .e-duration-500 {
  transition-duration: 500ms;
}

@layer utils {
  @font-face {
    font-family: "Carter One";

    src: url(/static/media/CarterOne-Regular.9c01ef3c.woff2) format("woff2");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: "Roboto";

    src: url(/static/media/Roboto-Regular.3fac9d47.woff2) format("woff2");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url(/static/media/ProximaNova-Light.425e81d7.woff) format("woff");

    font-weight: 300;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url(/static/media/ProximaNova-Regular.6bb1f7f6.woff) format("woff");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url(/static/media/ProximaNova-SemiBold.5ecb269b.woff) format("woff");

    font-weight: 500;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url(/static/media/ProximaNova-Bold.337db352.woff) format("woff");

    font-weight: 600;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url(/static/media/ProximaNova-Extrabld.ccf59c77.woff) format("woff");

    font-weight: 700;

    font-style: normal;

    font-display: swap;
  }
}

@media (max-width: 575px) {
  .landing_page .ui.selection.dropdown>.sm\:data-\[show\=true\]\:visible[data-show="true"].menu {
    display: flex;
    flex-direction: column;
  }
}

.landing_page .before\:e-absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.landing_page .before\:-e-top-1\.5::before {
  content: var(--tw-content);
  top: -0.375rem;
}

.landing_page .before\:e-left-0::before {
  content: var(--tw-content);
  left: 0px;
}

.landing_page .before\:e-mt-0\.5::before {
  content: var(--tw-content);
  margin-top: 0.125rem;
}

.landing_page .before\:e-h-0\.5::before {
  content: var(--tw-content);
  height: 0.125rem;
}

.landing_page .before\:e-w-4::before {
  content: var(--tw-content);
  width: 1rem;
}

.landing_page .before\:e-flex-1::before {
  content: var(--tw-content);
  flex: 1 1;
}

.landing_page .before\:e-border-t::before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.landing_page .before\:e-border-gray-200::before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.landing_page .before\:e-bg-black::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .before\:e-transition::before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .after\:e-absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.landing_page .after\:-e-bottom-1\.5::after {
  content: var(--tw-content);
  bottom: -0.375rem;
}

.landing_page .after\:e-left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.landing_page .after\:e-mt-0\.5::after {
  content: var(--tw-content);
  margin-top: 0.125rem;
}

.landing_page .after\:e-h-0\.5::after {
  content: var(--tw-content);
  height: 0.125rem;
}

.landing_page .after\:e-w-2\.5::after {
  content: var(--tw-content);
  width: 0.625rem;
}

.landing_page .after\:e-flex-1::after {
  content: var(--tw-content);
  flex: 1 1;
}

.landing_page .after\:e-border-t::after {
  content: var(--tw-content);
  border-top-width: 1px;
}

.landing_page .after\:e-border-gray-200::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.landing_page .after\:e-bg-black::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .after\:e-transition::after {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .hover\:e-bg-primary\/5:hover {
  background-color: rgb(17 126 228 / 0.05);
}

.landing_page .hover\:e-text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

.landing_page .focus\:e-border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(17 126 228 / var(--tw-border-opacity));
}

.landing_page .e-group:hover .group-hover\:e-translate-x-1\.5 {
  --tw-translate-x: 0.375rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:checked~.peer-checked\:e-border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(17 126 228 / var(--tw-border-opacity));
}

.landing_page .e-peer:checked~.peer-checked\:e-bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(17 126 228 / var(--tw-bg-opacity));
}

.landing_page .e-peer:checked~.peer-checked\:e-bg-transparent {
  background-color: transparent;
}

.landing_page .e-peer:checked~.peer-checked\:before\:e-top-0::before {
  content: var(--tw-content);
  top: 0px;
}

.landing_page .e-peer:checked~.peer-checked\:before\:-e-rotate-45::before {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-top-0::after {
  content: var(--tw-content);
  top: 0px;
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-w-4::after {
  content: var(--tw-content);
  width: 1rem;
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-rotate-45::after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:focus~.peer-focus\:e-text-primary {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

@media (max-width: 1279px) {
  .landing_page .xl\:e-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .landing_page .xl\:e-space-y-6> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
}

@media (max-width: 1023px) {
  .landing_page .lg\:e-col-span-full {
    grid-column: 1 / -1;
  }

  .landing_page .lg\:e-hidden {
    display: none;
  }

  .landing_page .lg\:e-h-full {
    height: 100%;
  }

  .landing_page .lg\:e-w-full {
    width: 100%;
  }

  .landing_page .lg\:e-translate-x-20 {
    --tw-translate-x: 5rem;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .lg\:e-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .lg\:e-columns-2 {
    -webkit-columns: 2;
            columns: 2;
  }

  .landing_page .lg\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .lg\:e-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .landing_page .lg\:e-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .landing_page .md\:e-absolute {
    position: absolute;
  }

  .landing_page .md\:e-bottom-0 {
    bottom: 0px;
  }

  .landing_page .md\:e-right-0 {
    right: 0px;
  }

  .landing_page .md\:e-z-10 {
    z-index: 10;
  }

  .landing_page .md\:e-origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .landing_page .md\:e-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .md\:e-columns-1 {
    -webkit-columns: 1;
            columns: 1;
  }

  .landing_page .md\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .md\:e-flex-col {
    flex-direction: column;
  }

  .landing_page .md\:e-gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .landing_page .md\:e-p-6 {
    padding: 1.5rem;
  }

  .landing_page .md\:e-pr-20 {
    padding-right: 5rem;
  }

  .landing_page .md\:e-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .landing_page .md\:e-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (max-width: 575px) {
  .landing_page .sm\:e-invisible {
    visibility: hidden;
  }

  .landing_page .sm\:e-col-span-full {
    grid-column: 1 / -1;
  }

  .landing_page .sm\:e-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .landing_page .sm\:\!e-mr-auto {
    margin-right: auto !important;
  }

  .landing_page .sm\:e-mb-0 {
    margin-bottom: 0px;
  }

  .landing_page .sm\:e-ml-0 {
    margin-left: 0px;
  }

  .landing_page .sm\:e-flex {
    display: flex;
  }

  .landing_page .sm\:e-hidden {
    display: none;
  }

  .landing_page .sm\:e-h-auto {
    height: auto;
  }

  .landing_page .sm\:e-h-fit {
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
  }

  .landing_page .sm\:e-max-h-0 {
    max-height: 0px;
  }

  .landing_page .sm\:e-w-full {
    width: 100%;
  }

  .landing_page .sm\:-e-translate-y-full {
    --tw-translate-y: -100%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e--translate-y-full {
    --tw-translate-y: -100%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e-scale-35 {
    --tw-scale-x: 0.35;
    --tw-scale-y: 0.35;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .sm\:e-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .landing_page .sm\:e-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .landing_page .sm\:e-space-x-4> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .landing_page .sm\:e-border-b {
    border-bottom-width: 1px;
  }

  .landing_page .sm\:e-bg-none {
    background-image: none;
  }

  .landing_page .sm\:e-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .landing_page .sm\:e-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .landing_page .sm\:e-text-inherit {
    color: inherit;
  }

  .landing_page .sm\:e-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .landing_page .e-peer:checked~.sm\:peer-checked\:e-mb-4 {
    margin-bottom: 1rem;
  }

  .landing_page .e-peer:checked~.sm\:peer-checked\:e-flex {
    display: flex;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-visible[data-show="true"] {
    visibility: visible;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-mb-2[data-show="true"] {
    margin-bottom: 0.5rem;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-max-h-14[data-show="true"] {
    max-height: 3.5rem;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-translate-y-0[data-show="true"] {
    --tw-translate-y: 0px;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:data-\[show\=true\]\:e-pb-2[data-show="true"] {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 447px) {
  .landing_page .xs\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.landing_page .\[\&\>li\]\:e-flex>li {
  display: flex;
}

.landing_page .\[\&\>li\]\:e-whitespace-nowrap>li {
  white-space: nowrap;
}

.landing_page .\[\&\>li\]\:before\:e-mr-2\.5>li::before {
  content: var(--tw-content);
  margin-right: 0.625rem;
}

.landing_page .\[\&\>li\]\:before\:e-inline-block>li::before {
  content: var(--tw-content);
  display: inline-block;
}

.landing_page .\[\&\>li\]\:before\:e-h-6>li::before {
  content: var(--tw-content);
  height: 1.5rem;
}

.landing_page .\[\&\>li\]\:before\:e-w-6>li::before {
  content: var(--tw-content);
  width: 1.5rem;
}

.landing_page .\[\&\>li\]\:before\:e-bg-\[url\(\.\.\/images\/checkmark\.svg\)\]>li::before {
  content: var(--tw-content);
  background-image: url(/static/media/checkmark.64990e65.svg);
}

/* Common */
.landing-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  padding: 54px 40px;
}

.landing-modal-cross {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}

.landing-modal-cross:hover {
  opacity: 1;
}

.landing-modal-header {
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}

.landing-modal-header>span {
  border-bottom: 1px solid #000;
}

.landing-modal-form {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  font-size: 16px;
  font-weight: 400;
}

.landing-modal-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-modal-form-note {
  font-style: italic;
}

.landing-modal-form-message {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  width: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.landing-modal-form-message.success {
  color: green;
}

.landing-modal-form-message.error {
  color: red;
}

.landing-modal-form-input {
  width: 100%;
  height: 48px;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0 1px 0 0 #000;
  border: none;
  outline: none;
}

.landing-modal-form-checkbox>label {
  display: flex;
  align-items: flex-start;
  grid-gap: 4px;
  gap: 4px;
}

.landing-modal-form-checkbox input,
.landing-modal-form-checkbox label {
  cursor: pointer;
}

.landing-modal-form-controls {
  display: flex;
  justify-content: flex-end;
  font-size: 32px;
  font-weight: 400;
}

.landing-modal-form-controls>input {
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
}

.landing-modal-form-controls>input:disabled {
  cursor: not-allowed;
  color: rgba(16, 16, 16, 0.3);
}

.landing-modal-login-kundelik {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
  width: 100%;
  padding: 8px 12px;
  background-color: gainsboro;
  border-radius: 12px;
  font-family: sans-serif;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.8;
}

.landing-modal-login-kundelik:hover {
  opacity: 1;
}

.landing-modal-login-kundelik>img {
  width: 96px;
}

/* Authentication (login/forgot password) */
.landing-modal-form-toggle-login>span {
  cursor: pointer;
}

.landing-modal-form-toggle-login>span {
  color: #324f7e;
}

.landing-modal-form-toggle-login>span:hover {
  border-bottom: 1px solid #324f7e;
}

/* Authentication (registration) */

/* Other modals */
.landing-modal-about.text,
.landing-modal-manual.text {
  line-height: 24px;
  font-size: 16px;
  text-align: justify;
}

.landing-modal-terms {
  text-align: justify;
}

.landing-modal-about.contacts__list {
  display: flex;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.landing-modal-about.contacts__item {
  line-height: 1;
  font-size: 18px;
}

.landing-modal-manual ol {
  margin-top: 0;
  padding-left: 2rem;
}

@media only screen and (max-width: 480px) {
  .landing-modal {
    padding: 40px 32px;
  }

  .landing-modal-cross {
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
  }

  .landing-modal-header {
    font-size: 28px;
    line-height: 28px;
  }

  .landing-modal-form-input {
    height: 36px;
    line-height: 18px;
    font-size: 16px;
  }

  .landing-modal-form-controls {
    font-size: 24px;
  }
}

@media only screen and (max-width: 400px) {
  .landing-modal {
    padding: 36px 24px;
    font-size: 14px;
  }

  .landing-modal-cross {
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
  }

  .landing-modal-header {
    font-size: 22px;
    line-height: 22px;
  }

  .landing-modal-form-input {
    height: 32px;
    font-size: 14px;
    line-height: 14px;
  }

  .landing-modal-form-controls {
    font-size: 20px;
  }

  .landing-modal-about .contacts__list {
    grid-gap: 16px;
    gap: 16px;
  }

  .landing-modal-about .contacts__item {
    font-size: 14px;
  }
}
/*
 * CKEditor 5 (v31.0.0) content styles.
 * Generated on Thu, 28 Oct 2021 07:37:40 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* custom code */
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ck-content {
  line-height: normal !important;
  white-space: pre-wrap;
  tab-size: 4;
}

.ck-content pre {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.ck-content pre code {
  display: block;
  position: relative;
  padding: 1rem !important;
  color: #353535;
  background: hsla(0,0%,78%,.3);
  border: 1px solid #c4c4c4;
  border-radius: 2px !important;
  line-height: 1.35;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.ck-content pre:after {
  content: none !important;
}
.ck-content .alert-yellow {
  color: #664d03;
  background-color: #fff3cd !important;
  border-color: #ffecb5;
}
.ck-content .alert-green {
  color: #0f5132;
  background-color: #d1e7dd !important;
  border-color: #badbcc;
}
.ck-content .alert-red {
  color: #842029;
  background-color: #f8d7da !important;
  border-color: #f5c2c7;
}
.ck-content .alert-blue {
  color: #084298;
  background-color: #cfe2ff !important;
  border-color: #b6d4fe;
}
.ck-content .alert-ltblue {
  color: #055160;
  background-color: #cff4fc !important;
  border-color: #b6effb;
}
.ck-content .alert-grey {
  color: #41464b;
  background-color: #e2e3e5!important;
  border-color: #d3d6d8;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - 1.5em);
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: calc(1.5em / 2);
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: calc(1.5em / 2);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: calc(1.5em / 2);
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: calc(1.5em / 2);
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: hsl(60, 97%, 73%);
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: hsl(120, 93%, 68%);
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: hsl(345, 96%, 73%);
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: hsl(201, 97%, 72%);
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: hsl(0, 85%, 49%);
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: hsl(112, 100%, 27%);
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-image-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  /* margin: 0.9em auto; */
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir='rtl'] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-table-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
  max-width: 100%;
  overflow: auto;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='rtl'] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='ltr'] .table th {
  text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 16px;
  width: var(--ck-todo-list-checkmark-size);
  height: 16px;
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc(16px / 3);
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(16px / 5.3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(16px / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(16px / 2.6);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(16px / 8) calc(16px / 8) 0;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  /* margin: 15px 0; */
  margin-top: -10px;
  margin-bottom: 15px;
  /* height: 4px; */
  height: 2px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: hsla(341, 100%, 30%, 0.1);
  background: var(--ck-color-mention-background);
  color: hsl(341, 100%, 30%);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}


:root {
  --opacity: 1;
  --color: 28, 41, 61;
  --radius: 5px;
}

.cs-square {--radius: 0;}
.cs-rounded {--radius: 10px;}
.cs-rounded-full {--radius: 9999px;}

.ck-content .cs-exercise,
.ck-content .cs-exercise-2,
.ck-content .cs-exercise-3,
.ck-content .cs-definition,
.ck-content .cs-definition-2,
.ck-content .cs-definition-3,
.ck-content .cs-color,
.ck-content .cs-square,
.ck-content .cs-rounded,
.ck-content .cs-rounded-full,
.ck-content .cs-icon {
  border: none;
  font-style: initial;
  position: relative;
  overflow: initial;
}

.ck-content .cs-exercise > .table,
.ck-content .cs-exercise > ul,
.ck-content .cs-exercise > ol,
.ck-content .cs-exercise-2 > .table,
.ck-content .cs-exercise-2 > ul,
.ck-content .cs-exercise-2 > ol,
.ck-content .cs-exercise-3 > .table,
.ck-content .cs-exercise-3 > ul,
.ck-content .cs-exercise-3 > ol,
.ck-content .cs-definition > .table,
.ck-content .cs-definition > ul,
.ck-content .cs-definition > ol,
.ck-content .cs-definition-2 > .table,
.ck-content .cs-definition-2 > ul,
.ck-content .cs-definition-2 > ol,
.ck-content .cs-definition-3 > .table,
.ck-content .cs-definition-3 > ul,
.ck-content .cs-definition-3 > ol,
.ck-content .cs-color > .table,
.ck-content .cs-color > ul,
.ck-content .cs-color > ol,
.ck-content .cs-square > .table,
.ck-content .cs-square > ul,
.ck-content .cs-square > ol,
.ck-content .cs-rounded > .table,
.ck-content .cs-rounded > ul,
.ck-content .cs-rounded > ol,
.ck-content .cs-rounded-full > .table,
.ck-content .cs-rounded-full > ul,
.ck-content .cs-rounded-full > ol,
.ck-content .cs-icon > .table,
.ck-content .cs-icon > ul,
.ck-content .cs-icon > ol {
  margin-top: 0;
}

.ck-content .cs-exercise {
  --opacity: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: initial;
  border-left: 1px solid rgba(28, 41, 61, 1);
  border-left: 1px solid rgba(var(--color), var(--opacity));
}

.ck-content .cs-exercise::after {
  --opacity: 1;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background: rgba(28, 41, 61, 1);
  background: rgba(var(--color), var(--opacity));
  display: block;
  position: absolute;
  left: -4px;
  bottom: -5px;
}

.ck-content .cs-exercise-2 {
  --opacity: 0.1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border-radius: var(--radius);
  overflow: initial;
  background: rgb(28, 41, 61, 1);
  background: rgb(var(--color), var(--opacity));
}

.ck-content .cs-exercise-2.cs-exercise-3 {
  --opacity: 0.1;
}
.ck-content .cs-exercise-2.cs-definition-3 {
  border-color: rgb(28, 41, 61);
  border-color: rgb(var(--color));
}
.ck-content .cs-exercise-2.cs-definition-3.cs-icon::before,
.ck-content .cs-exercise-2.cs-exercise-3.cs-icon::before {
  outline: 3px solid #fff!important;
}
.ck-content .cs-exercise-2.cs-definition-3,
.ck-content .cs-exercise.cs-definition-3 {
  padding: 16px!important;
}
.ck-content .cs-exercise.cs-exercise-2::after,
.ck-content .cs-exercise.cs-definition-3::after {
  display: none;
}

.ck-content .cs-exercise.cs-icon.cs-icon::before,
.ck-content .cs-exercise-2.cs-icon.cs-icon::before,
.ck-content .cs-exercise-3.cs-icon.cs-icon::before {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  top: -16px;
  left: -16px;
  outline: 3px solid #fff;
  box-shadow: 0 0 0 4px rgb(28, 41, 61);
  box-shadow: 0 0 0 4px rgb(var(--color));
}
.ck-content .cs-exercise-2.cs-icon.cs-icon::before {
  outline: none;
}

.ck-content .cs-exercise-3 {
  --opacity: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border-radius: var(--radius);
  overflow: initial;
  border: 3px solid rgb(28, 41, 61);
  border: 3px solid rgb(var(--color));
}

.ck-content .cs-definition {
  box-shadow: rgb(28 41 61 / 5%) 0px 0px 20px, rgb(28 41 61 / 6%) 0px 12px 20px;
  width: 100%;
  min-height: 72px;
  border-radius: 5px;
  border-radius: var(--radius);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ck-content .cs-definition.cs-color {
  --opacity: 0.1;
  background: rgba(28, 41, 61, 1);
  background: rgba(var(--color), var(--opacity));
  box-shadow: none!important;
}

.ck-content .cs-definition-2,
.ck-content .cs-definition-3 {
  width: 100%;
  min-height: 72px;
  border-radius: 5px;
  border-radius: var(--radius);
  padding: 16px;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px 3px rgba(28, 41, 61, 1);
  box-shadow: inset 0px 0px 0px 3px rgba(var(--color), var(--opacity));
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ck-content .cs-definition.cs-rounded-full,
.ck-content .cs-definition-2.cs-rounded-full,
.ck-content .cs-definition-3.cs-rounded-full {
  padding: 20px 80px;
  border-radius: 99px;
}
.ck-content .cs-definition-3 {
  box-shadow: none;
  border: 2px dashed rgba(28, 41, 61, 1);
  border: 2px dashed rgba(var(--color), var(--opacity));
}

.ck-content .cs-definition.cs-icon,
.ck-content .cs-definition-2.cs-icon,
.ck-content .cs-definition-3.cs-icon {
  padding: 16px 16px 16px 72px;
}

.ck-content .cs-exercise-3.cs-definition.cs-icon {
  padding: 16px;
}

.ck-content .cs-exercise.cs-definition::after {
  display: none;
}
.ck-content .cs-exercise.cs-definition {
  border-width: 5px;
  border-color: rgba(28, 41, 61, 1);
  border-color: rgba(var(--color), 1);
}
.ck-content .cs-exercise.cs-definition.cs-icon {
  padding: 16px;
}

.cs-info {--color: 76, 153, 230;}
.cs-success {--color: 0, 211, 89;}
.cs-warning {--color: 255, 182, 8;}
.cs-error {--color: 255, 12, 12;}
.cs-magenta {--color: 189, 51, 164;}
.cs-indigo {--color: 75, 0, 130;}
.cs-purple {--color: 148, 0, 211;}
.cs-brown {--color: 121, 68, 59;}
.cs-orange {--color: 237, 118, 14;}
.cs-green {--color: 0, 138, 0;}
.cs-mint {--color: 62, 180, 137;}
.cs-blue {--color: 31, 58, 147;}
.cs-grey {--color: 128, 128, 128;}
.cs-softcyan {--color: 120, 219, 226;}
.cs-burgundy {--color: 176, 0, 0;}
.cs-pink {--color: 255, 44, 129;}
.cs-standart {--color: 28, 41, 61;}
.cs-peru {--color: 222, 170, 136;}

.ck-content .cs-definition.cs-icon::before,
.ck-content .cs-definition-2.cs-icon::before,
.ck-content .cs-definition-3.cs-icon::before {
  top: 16px;
  left: 16px;
}

.cs-icon::before {
  --opacity: 1;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zM9 10v6h6v-6H9zm2 2h2v2h-2v-2z'/%3E%3C/svg%3E"); 
  background-color: rgba(28, 41, 61, 1); 
  background-color: rgba(var(--color), var(--opacity));;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: -5px;
  left: -20px;
}

.cs-icon.ic-mail::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 5.5V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9zM0 10h5v2H0v-2zm0 5h8v2H0v-2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-attach::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M14 13.5V8a4 4 0 1 0-8 0v5.5a6.5 6.5 0 1 0 13 0V4h2v9.5a8.5 8.5 0 1 1-17 0V8a6 6 0 1 1 12 0v5.5a3.5 3.5 0 0 1-7 0V8h2v5.5a1.5 1.5 0 0 0 3 0z'/%3E%3C/svg%3E");
}
.cs-icon.ic-award::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 15.245v6.872a.5.5 0 0 1-.757.429L12 20l-4.243 2.546a.5.5 0 0 1-.757-.43v-6.87a8 8 0 1 1 10 0zm-8 1.173v3.05l3-1.8 3 1.8v-3.05A7.978 7.978 0 0 1 12 17a7.978 7.978 0 0 1-3-.582zM12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
}
.cs-icon.ic-chart::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 12h4v9H3v-9zm14-4h4v13h-4V8zm-7-6h4v19h-4V2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-bubble::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0L24 0 24 24 0 24z'/%3E%3Cpath d='M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm8.5-10C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-bookmark::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1z'/%3E%3C/svg%3E");
}
.cs-icon.ic-calendar::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-flag::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 3h19.138a.5.5 0 0 1 .435.748L18 10l3.573 6.252a.5.5 0 0 1-.435.748H4v5H2V3z'/%3E%3C/svg%3E");
}
.cs-icon.ic-link::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-print::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7 17h10v5H7v-5zm12 3v-5H5v5H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-2zM5 10v2h3v-2H5zm2-8h10a1 1 0 0 1 1 1v3H6V3a1 1 0 0 1 1-1z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-chat::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-chat-2::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-message::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 8.994A5.99 5.99 0 0 1 8 3h8c3.313 0 6 2.695 6 5.994V21H8c-3.313 0-6-2.695-6-5.994V8.994zM14 11v2h2v-2h-2zm-6 0v2h2v-2H8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-edit::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M9.243 19H21v2H3v-4.243l9.9-9.9 4.242 4.244L9.242 19zm5.07-13.556l2.122-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-quil::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M21 2C6 2 4 16 3 22h1.998c.666-3.333 2.333-5.166 5.002-5.5 4-.5 7-4 8-7l-1.5-1 1-1c1-1 2.004-2.5 3.5-5.5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pen::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4.929 21.485l5.846-5.846a2 2 0 1 0-1.414-1.414l-5.846 5.846-1.06-1.06c2.827-3.3 3.888-6.954 5.302-13.082l6.364-.707 5.657 5.657-.707 6.364c-6.128 1.414-9.782 2.475-13.081 5.303l-1.061-1.06zM16.596 2.04l6.347 6.346a.5.5 0 0 1-.277.848l-1.474.23-5.656-5.656.212-1.485a.5.5 0 0 1 .848-.283z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-markup::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm5.051-3.796l-.862-3.447a1 1 0 0 0-.97-.757H8.781a1 1 0 0 0-.97.757l-.862 3.447A7.967 7.967 0 0 0 12 20a7.967 7.967 0 0 0 5.051-1.796zM10 12h4v-1.5l-1.038-3.635a1 1 0 0 0-1.924 0L10 10.5V12z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-ruler::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pen-ruler::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.636 12.707l1.828 1.829L8.88 13.12 7.05 11.293l1.414-1.414 1.829 1.828 1.414-1.414L9.88 8.464l1.414-1.414L13.12 8.88l1.415-1.415-1.829-1.828 2.829-2.828a1 1 0 0 1 1.414 0l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414l2.828-2.829zm8.485 5.656l4.243-4.242L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 5.636 9.878z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-palette::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zM7.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM12 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-magic::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M15.224 15.508l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-tools::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.33 3.271a3.5 3.5 0 0 1 4.472 4.474L20.647 18.59l-2.122 2.121L7.68 9.867a3.5 3.5 0 0 1-4.472-4.474L5.444 7.63a1.5 1.5 0 1 0 2.121-2.121L5.329 3.27zm10.367 1.884l3.182-1.768 1.414 1.414-1.768 3.182-1.768.354-2.12 2.121-1.415-1.414 2.121-2.121.354-1.768zm-7.071 7.778l2.121 2.122-4.95 4.95A1.5 1.5 0 0 1 3.58 17.99l.097-.107 4.95-4.95z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-grid::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-bug::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6.056 8.3a7.01 7.01 0 0 1 .199-.3h11.49c.069.098.135.199.199.3l2.02-1.166 1 1.732-2.213 1.278c.162.59.249 1.213.249 1.856v1h3v2h-3c0 .953-.19 1.862-.536 2.69l2.5 1.444-1 1.732-2.526-1.458A6.992 6.992 0 0 1 13 21.929V14h-2v7.93a6.992 6.992 0 0 1-4.438-2.522l-2.526 1.458-1-1.732 2.5-1.443A6.979 6.979 0 0 1 5 15H2v-2h3v-1c0-.643.087-1.265.249-1.856L3.036 8.866l1-1.732L6.056 8.3zM8 6a4 4 0 1 1 8 0H8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-cursor::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.91 12.36L17 20.854l-2.818 1.026-3.092-8.494-4.172 3.156 1.49-14.909 10.726 10.463z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-computer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 16h16V5H4v11zm9 2v2h4v2H7v-2h4v-2H2.992A.998.998 0 0 1 2 16.993V4.007C2 3.451 2.455 3 2.992 3h18.016c.548 0 .992.449.992 1.007v12.986c0 .556-.455 1.007-.992 1.007H13z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-save::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 3h13l3.707 3.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm8 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM5 5v4h10V5H5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-mouse::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.141 2h1.718c2.014 0 3.094.278 4.072.801a5.452 5.452 0 0 1 2.268 2.268c.523.978.801 2.058.801 4.072v5.718c0 2.014-.278 3.094-.801 4.072a5.452 5.452 0 0 1-2.268 2.268c-.978.523-2.058.801-4.072.801H11.14c-2.014 0-3.094-.278-4.072-.801a5.452 5.452 0 0 1-2.268-2.268C4.278 17.953 4 16.873 4 14.859V9.14c0-2.014.278-3.094.801-4.072A5.452 5.452 0 0 1 7.07 2.801C8.047 2.278 9.127 2 11.141 2zM11 6v5h2V6h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-restart::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.537 19.567A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3a8 8 0 1 0-2.46 5.772l.997 1.795z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-paper::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 2a3 3 0 0 1 3 3v2h-2v12a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3v-2h16v2a1 1 0 0 0 .883.993L18 20a1 1 0 0 0 .993-.883L19 19v-4H3V5a3 3 0 0 1 3-3h14z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-file-list::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19 22H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12h4v4a3 3 0 0 1-3 3zm-1-5v2a1 1 0 0 0 2 0v-2h-2zm-2 3V4H4v15a1 1 0 0 0 1 1h11zM6 7h8v2H6V7zm0 4h8v2H6v-2zm0 4h5v2H6v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-book::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 22H6.5A3.5 3.5 0 0 1 3 18.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2v-3H6.5a1.5 1.5 0 0 0 0 3H19zM10 4v8l3.5-2 3.5 2V4h-7z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-align::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-link::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-quote::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-paragraph::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-asterisk::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 3v7.267l6.294-3.633 1 1.732-6.293 3.633 6.293 3.635-1 1.732L13 13.732V21h-2v-7.268l-6.294 3.634-1-1.732L9.999 12 3.706 8.366l1-1.732L11 10.267V3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-exclamation::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M10.5 20.6c0-1 .7-1.7 1.6-1.7 1 0 1.6.7 1.6 1.7 0 .9-.6 1.7-1.6 1.7s-1.6-.7-1.6-1.7zm.4-3.8-.4-14.5h3.2l-.5 14.5h-2.3z'/%3E%3C/svg%3E");
}
.cs-icon.ic-question::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M12 19c.828 0 1.5.672 1.5 1.5S12.828 22 12 22s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-17c3.314 0 6 2.686 6 6 0 2.165-.753 3.29-2.674 4.923C13.399 14.56 13 15.297 13 17h-2c0-2.474.787-3.695 3.031-5.601C15.548 10.11 16 9.434 16 8c0-2.21-1.79-4-4-4S8 5.79 8 8v1H6V8c0-3.314 2.686-6 6-6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-heart::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-flask::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M16 2v2h-1v3.243c0 1.158.251 2.301.736 3.352l4.282 9.276c.347.753.018 1.644-.734 1.99-.197.092-.411.139-.628.139H5.344c-.828 0-1.5-.672-1.5-1.5 0-.217.047-.432.138-.629l4.282-9.276C8.749 9.545 9 8.401 9 7.243V4H8V2h8zm-3 2h-2v4h2V4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-tube::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M17 2v2h-1v14c0 2.21-1.79 4-4 4s-4-1.79-4-4V4H7V2h10zm-4 13c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-2-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm3-8h-4v4h4V4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-microscope::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M13.196 2.268l3.25 5.63c.276.477.112 1.089-.366 1.365l-1.3.75 1.001 1.732-1.732 1-1-1.733-1.299.751c-.478.276-1.09.112-1.366-.366L8.546 8.215C6.494 8.837 5 10.745 5 13c0 .625.115 1.224.324 1.776C6.1 14.284 7.016 14 8 14c1.684 0 3.174.833 4.08 2.109l7.688-4.439 1 1.732-7.878 4.549c.072.338.11.69.11 1.049 0 .343-.034.677-.1 1H21v2l-17 .001c-.628-.836-1-1.875-1-3.001 0-1.007.298-1.945.81-2.73C3.293 15.295 3 14.182 3 13c0-2.995 1.881-5.551 4.527-6.55l-.393-.682c-.552-.957-.225-2.18.732-2.732l2.598-1.5c.957-.552 2.18-.225 2.732.732z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-virus::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M13.717 1.947l3.734 1.434-.717 1.867-.934-.359-.746 1.945c.779.462 1.444 1.094 1.945 1.846l1.903-.847-.407-.914 1.827-.813 1.627 3.654-1.827.813-.407-.913-1.902.847c.122.477.187.978.187 1.493 0 .406-.04.803-.117 1.187l1.944.746.358-.933 1.868.717-1.434 3.734-1.867-.717.358-.933-1.944-.747c-.462.779-1.094 1.444-1.846 1.945l.847 1.903.914-.407.813 1.827-3.654 1.627-.813-1.827.913-.407-.847-1.902c-.477.122-.978.187-1.493.187-.407 0-.804-.04-1.188-.118l-.746 1.945.934.358-.717 1.868-3.734-1.434.717-1.867.932.358.748-1.944C8.167 16.704 7.502 16.072 7 15.32l-1.903.847.407.914-1.827.813-1.627-3.654 1.827-.813.406.914 1.903-.848C6.065 13.016 6 12.515 6 12c0-.406.04-.803.117-1.187l-1.945-.746-.357.933-1.868-.717L3.381 6.55l1.867.717-.359.933 1.945.747C7.296 8.167 7.928 7.502 8.68 7l-.847-1.903-.914.407-.813-1.827L9.76 2.051l.813 1.827-.913.407.847 1.902C10.984 6.065 11.485 6 12 6c.406 0 .803.04 1.187.117l.745-1.945L13 3.815l.717-1.868zm-3.583 11.285c-.276.478-.112 1.09.366 1.366s1.09.112 1.366-.366.112-1.09-.366-1.366-1.09-.112-1.366.366zM14 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-3.5-1.598c-.478.276-.642.888-.366 1.366.276.478.888.642 1.366.366.478-.276.642-.888.366-1.366-.276-.478-.888-.642-1.366-.366z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-map::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pushpin::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18 3v2h-1v6l2 3v2h-6v7h-2v-7H5v-2l2-3V5H6V3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-rocket::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.33 15.929A13.064 13.064 0 0 1 5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 1.01-.114 1.991-.33 2.929l2.02 1.796a.5.5 0 0 1 .097.63l-2.458 4.096a.5.5 0 0 1-.782.096l-2.254-2.254a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.254 2.254a.5.5 0 0 1-.782-.096l-2.458-4.095a.5.5 0 0 1 .097-.631l2.02-1.796zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-globe::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 21h5v2H6v-2h5v-1.05a10.002 10.002 0 0 1-7.684-4.988l1.737-.992A8 8 0 1 0 15.97 3.053l.992-1.737A9.996 9.996 0 0 1 22 10c0 5.185-3.947 9.449-9 9.95V21zm-1-4a7 7 0 1 1 0-14 7 7 0 0 1 0 14z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-compass::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm3.5-13.5l-5 2-2 5 5-2 2-5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-anchor::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 9.874v10.054c3.619-.453 6.487-3.336 6.938-6.972H17L20.704 7A10.041 10.041 0 0 1 22 11.95C22 17.5 17.523 22 12 22S2 17.5 2 11.95c0-1.8.471-3.489 1.296-4.95L7 12.956H4.062c.451 3.636 3.32 6.519 6.938 6.972V9.874A4.002 4.002 0 0 1 12 2a4 4 0 0 1 1 7.874zM12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-image::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 5H4v14l9.292-9.294a1 1 0 0 1 1.414 0L20 15.01V5zM2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-video::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zm8.622 4.422a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.4.4 0 0 0 0-.666l-4.88-3.252z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-music::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-headphone::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 12h3a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7C2 6.477 6.477 2 12 2s10 4.477 10 10v7a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h3a8 8 0 1 0-16 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-mic::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 1a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-volume::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm13.517 4.134l-1.416-1.416A8.978 8.978 0 0 0 21 12a8.982 8.982 0 0 0-3.304-6.968l1.42-1.42A10.976 10.976 0 0 1 23 12c0 3.223-1.386 6.122-3.594 8.134zm-3.543-3.543l-1.422-1.422A3.993 3.993 0 0 0 16 12c0-1.43-.75-2.685-1.88-3.392l1.439-1.439A5.991 5.991 0 0 1 18 12c0 1.842-.83 3.49-2.137 4.591z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-notify::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM9.5 21h5a2.5 2.5 0 1 1-5 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-repeat::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6 4h15a1 1 0 0 1 1 1v7h-2V6H6v3L1 5l5-4v3zm12 16H3a1 1 0 0 1-1-1v-7h2v6h14v-3l5 4-5 4v-3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-star::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-cog::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.334 4.545a9.99 9.99 0 0 1 3.542-2.048A3.993 3.993 0 0 0 12 3.999a3.993 3.993 0 0 0 3.124-1.502 9.99 9.99 0 0 1 3.542 2.048 3.993 3.993 0 0 0 .262 3.454 3.993 3.993 0 0 0 2.863 1.955 10.043 10.043 0 0 1 0 4.09c-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 0 0-.262 3.455 9.99 9.99 0 0 1-3.542 2.047A3.993 3.993 0 0 0 12 20a3.993 3.993 0 0 0-3.124 1.502 9.99 9.99 0 0 1-3.542-2.047 3.993 3.993 0 0 0-.262-3.455 3.993 3.993 0 0 0-2.863-1.954 10.043 10.043 0 0 1 0-4.091 3.993 3.993 0 0 0 2.863-1.955 3.993 3.993 0 0 0 .262-3.454zM13.5 14.597a3 3 0 1 0-3-5.196 3 3 0 0 0 3 5.196z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-info::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-warning::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-alert::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zM11 16v2h2v-2h-2zm0-7v5h2V9h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-check::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-refresh::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-shield::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-eye::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-search::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-share::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-timer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17.618 5.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 1 1-1.414-1.414zM11 8v6h2V8h-2zM8 1h8v2H8V1z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-user::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-fire::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 23a7.5 7.5 0 0 1-5.138-12.963C8.204 8.774 11.5 6.5 11 1.5c6 4 9 8 3 14 1 0 2.5 0 5-2.47.27.773.5 1.604.5 2.47A7.5 7.5 0 0 1 12 23z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-bulb::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11 18H7.941c-.297-1.273-1.637-2.314-2.187-3a8 8 0 1 1 12.49.002c-.55.685-1.888 1.726-2.185 2.998H13v-5h-2v5zm5 2v1a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-1h8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-temp::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M8 5a4 4 0 1 1 8 0v5.255a7 7 0 1 1-8 0V5zm1.144 6.895a5 5 0 1 0 5.712 0L14 11.298V5a2 2 0 1 0-4 0v6.298l-.856.597zM8 16h8a4 4 0 1 1-8 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-earth::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.355-6.048v-.105c0-.922 0-1.343-.652-1.716a7.374 7.374 0 0 0-.645-.325c-.367-.167-.61-.276-.938-.756a12.014 12.014 0 0 1-.116-.172c-.345-.525-.594-.903-1.542-.753-1.865.296-2.003.624-2.085 1.178l-.013.091c-.121.81-.143 1.082.195 1.437 1.265 1.327 2.023 2.284 2.253 2.844.112.273.4 1.1.202 1.918a8.185 8.185 0 0 0 3.151-2.237c.11-.374.19-.84.19-1.404zM12 3.833c-2.317 0-4.41.966-5.896 2.516.177.123.331.296.437.534.204.457.204.928.204 1.345 0 .328 0 .64.105.865.144.308.766.44 1.315.554.197.042.399.084.583.135.506.14.898.595 1.211.96.13.151.323.374.42.43.05-.036.211-.211.29-.498.062-.22.044-.414-.045-.52-.56-.66-.529-1.93-.356-2.399.272-.739 1.122-.684 1.744-.644.232.015.45.03.614.009.622-.078.814-1.025.949-1.21.292-.4 1.186-1.003 1.74-1.375A8.138 8.138 0 0 0 12 3.833z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}



.ck-style-grid__button__preview blockquote {
  text-indent: -9999px;
  color: #fff;
}


.ck-style-grid__button__preview .cs-square,
.ck-style-grid__button__preview .cs-rounded,
.ck-style-grid__button__preview .cs-rounded-full {
  border: 2px solid #000;
  border-radius: 5px;
  border-radius: var(--radius);
}
.ck-style-grid__button__preview .cs-rounded {
  border-radius: 4px;
}
.ck-style-grid__button__preview .cs-color {
  background: rgb(28, 41, 61);
  background: rgb(var(--color));
}
.ck-style-grid__button__preview .cs-icon::before {
  top: -12px;
  left: 0;
}
.ck-style-grid__button__preview .cs-definition {
  background: rgba(0, 66, 237, 0.1);
}
.ck-style-grid__button__preview .cs-definition,
.ck-style-grid__button__preview .cs-definition-2,
.ck-style-grid__button__preview .cs-definition-3 {
  min-height: 20px;
  padding: 7px;
}


abbr[title] {
  position: relative;
  cursor: default;
  text-decoration: none;
  border-bottom: 1px dotted #000;
}

abbr[title]::before {
  content: attr(title);
  display: none;
  position: absolute;
  bottom: calc(-100% - 15px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 3px 5px;
  font-size: .9em;
  font-weight: bold;
  border-radius: 3px;
  color: #fff;
  background: #000;
  white-space: nowrap;
}

abbr[title]::after {
  content: "";
  display: none;
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) #000 rgba(0,0,0,0);
}

abbr[title]:hover::before, 
abbr[title]:hover::after {
  display: block;
}



.palette-block {
  display: none;
  position: absolute;
  left: 70px;
  background: rgba(0,0,0,.9);
  padding: .25rem 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 6rem;
  border-radius: 6px;
}

.mobile-navigation .palette-block {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: -10px;
  left: initial;
}

.mobile-navigation .palette-block .palette {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.palette-button {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
}

.mobile-navigation .palette-button {
  background-size: 16px;
}

.palette-button.show .palette-block {
  display: flex;
}

.palette-block::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-color: transparent rgba(0,0,0,.9)transparent transparent;
}

.palette-block .palette {
  width: 30px;
  height: 30px;
  display: block;
  background: transparent;
  border-radius: 5px;
  border:none;
  cursor: pointer;
  position: relative;
  transition: all .25s;
  margin: .25rem;
}

.palette-block .palette:hover {
  opacity: .9;
}
.palette-block .palette.active {
  box-shadow: 0px 5px 5px -5px;
  border: 3px solid rgba(0,0,0,.1);
}
.palette-block .palette.active::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  display: block;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'/%3E%3C/svg%3E") no-repeat center;
}

:root {
  --palette-opacity: 1;
}

.dashboard .content {
  --palette-opacity: .5;
}

.dashboard .content[color='#FFFFFF'],
.palette-block > .palette[color='#FFFFFF'] {
  background: rgba(255,255,255, 1);
}
.dashboard .content[color='#F2EFD7'],
.palette-block > .palette[color='#F2EFD7'] {
  background: rgba(242, 239, 215, 1);
  background: rgba(242, 239, 215, var(--palette-opacity));
}
.dashboard .content[color='#FFDFDF'],
.palette-block > .palette[color='#FFDFDF'] {
  background: rgba(255, 204, 153, 1);
  background: rgba(255, 204, 153, var(--palette-opacity));
}
.dashboard .content[color='#FFDFDF'],
.palette-block > .palette[color='#FFDFDF'] {
  background: rgba(255, 223, 223, 1);
  background: rgba(255, 223, 223, var(--palette-opacity));
}
.dashboard .content[color='#F5D9F5'],
.palette-block > .palette[color='#F5D9F5'] {
  background: rgba(245, 217, 245, 1);
  background: rgba(245, 217, 245, var(--palette-opacity));
}
.dashboard .content[color='#CFCFFF'],
.palette-block > .palette[color='#CFCFFF'] {
  background: rgba(231, 231, 255, 1);
  background: rgba(231, 231, 255, var(--palette-opacity));
}
.dashboard .content[color='#CFFFCF'],
.palette-block > .palette[color='#CFFFCF'] {
  background: rgba(231, 255, 231, 1);
  background: rgba(231, 255, 231, var(--palette-opacity));
}
.dashboard .content[color='#DFE6EC'],
.palette-block > .palette[color='#DFE6EC'] {
  background: rgba(223, 230, 236, 1);
  background: rgba(223, 230, 236, var(--palette-opacity));
}
.dashboard .content[color='#EEEDE1'],
.palette-block > .palette[color='#EEEDE1'] {
  background: rgba(238,237,225, 1);
  background: rgba(238,237,225, var(--palette-opacity));
}
.dashboard .content[color='#E7E7E7'],
.palette-block > .palette[color='#E7E7E7'] {
  background: rgba(231, 231, 231, 1);
  background: rgba(231, 231, 231, var(--palette-opacity));
}

