[data-name] {
    transition: all .3s ease;
}

[data-name].active {
    position: relative;
}

[data-name].active::before {
    content: '';
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #4682E0;
}
