.dashboard-practice-header-stats {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 80px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-header-stats-header {
    font-size: 20px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "subheader subheader"
        "rightText totalText"
        "rightNumber totalNumber";
}
.dashboard-practice-header-stats-results-subheader {
    grid-area: subheader;
    font-size: 18px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results-right-text {
    grid-area: rightText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-total-text {
    grid-area: totalText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-right-number {
    grid-area: rightNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
.dashboard-practice-header-stats-results-total-number {
    grid-area: totalNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-header-stats {
        margin: 0px 24px 32px 24px;
        padding: 16px 40px;
        font-size: 12px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 18px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 18px;
        line-height: 1;
    }
}
