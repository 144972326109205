.admin-block-question-form-option {
  display: flex;
  flex-direction: row;
}

.admin-block-question-delete-option {
  cursor: pointer;
  opacity: 0.5;
}

.admin-block-question-delete-option:hover {
  opacity: 1;
}
