.dashboard {
  display: grid;
  grid-template-areas: 'course-navigation content menu-navigation';
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 10fr 2fr;
  min-height: 100vh;
}
.dashboard .course-navigation {
  display: flex;
  justify-content: flex-start;
  grid-area: course-navigation;
}
.dashboard .content {
  grid-area: content;
  padding: 72px 24px;
  width: 100%;
}
.dashboard .menu-navigation {
  display: flex;
  justify-content: flex-end;
  grid-area: menu-navigation;
}
.dashboard .mobile-navigation {
  display: none;
}
.dashboard h1 {
  margin-bottom: 0.6em;
  color: #000;
  font-family: inherit;
  font-size: 36px;
  line-height: 48px;
}
.dashboard h2 {
  margin-bottom: 1.4em;
  color: #000;
  font-family: inherit;
  font-size: 32px;
  line-height: 32px;
}

@media print {
  .dashboard .course-navigation {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .dashboard .content {
    padding: 64px 16px;
  }
  .dashboard h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .dashboard h2 {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .dashboard {
    display: flex;
  }
  .dashboard .course-navigation,
  .dashboard .menu-navigation {
    display: none;
  }
  .dashboard .content {
    padding: 48px 32px 80px 32px;
  }
  .dashboard .mobile-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0px;
    width: 100%;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.75);
    border-top: 2px solid #4682e0;
  }
  .dashboard .mobile-navigation #print-button {
    display: none;
  }
  .dashboard h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .dashboard h2 {
    font-size: 22px;
    line-height: 22px;
  }
}
@media screen and (max-width: 400px) {
  .dashboard .content {
    padding: 32px 24px 72px 24px;
  }
  .dashboard h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .dashboard h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .dashboard .mobile-navigation {
    height: 56px;
  }
}
