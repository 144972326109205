.filter_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 1.5rem;
}

.filter_wrapper .select_wrapper {
    position: relative;
    display: flex;
    width: 256px;
}

.filter_wrapper .select_wrapper .select_title {
    position: relative;
    background: #fff;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    user-select: none;
    width: 100%;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.filter_wrapper .select_wrapper .select_title svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .75rem;
    transition: transform .25s ease;
}

.filter_wrapper .select_wrapper .hidden {
    display: none;
}

.filter_wrapper .select_wrapper .peer ~ .option_wrapper {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #96c8da;
    z-index: 1;
    background: #fff;
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    padding: .25rem 0;
    width: 100%;
    max-height: 356px;
    overflow-y: auto;
}

.filter_wrapper .select_wrapper .peer:checked ~ .option_wrapper {
    display: flex;
}

.filter_wrapper .select_wrapper .peer:checked ~ .select_title svg {
    transform: translateY(-50%) rotate(180deg);
}

.filter_wrapper .select_wrapper .option_wrapper label {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
}

.filter_wrapper .select_wrapper .option_wrapper label span {
    margin-left: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter_wrapper .select_wrapper .option_wrapper label span::after {
    content: '';
    position: absolute;
    inset: 0;
}

.filter_wrapper .select_wrapper .option_wrapper label:hover span::after {
    background: rgba(0,0,0,.05);
}

.filter_wrapper .select_wrapper .option_wrapper label input:checked ~ span::after {
    background: rgba(0,0,0,.03);
}

.search_wrapper,
.tags_wrapper {
    width: calc(256px * 3 + 16px * 2);
}

.tags_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    user-select: none;
}

.tags_wrapper label {
    display: flex;
    position: relative;
    background: rgba(0,0,0,.03);
    border-radius: 999px;
    padding: .25rem 2.1rem .25rem 0.75rem;
    cursor: pointer;
    width: fit-content;
    font-size: 90%;
    transition: all .25s ease;
    align-self: center;
}
.tags_wrapper label:hover {
    background: rgba(0,0,0,.05);
}
.tags_wrapper label::after {
    content: '\00D7';
    background: rgba(0,0,0,.05);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: .25rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 999px;
    font-weight: 400;
}
.tags_wrapper label:hover .tags_wrapper label::after {
    background: rgba(0,0,0,.07);
}

@media (min-width: 600px) and (max-width: 800px) {
    .filter_wrapper .select_wrapper {
        width: 196px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(196px * 3 + 14px * 2);
    }
}
@media (min-width: 400px) and (max-width: 600px) {
    .filter_wrapper {
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 160px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(160px * 3 + 8px * 2);
    }
}

@media (max-width: 400px) {
    .filter_wrapper {
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 144px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(144px * 3 + 14px * 2);
    }
}