.admin-analytics-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-analytics-class-subheader {
  margin-bottom: 1em;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.admin-analytics-search {
  margin: 2em auto;
}
.admin-analytics-class-search {
  margin-bottom: 2em;
}
@media screen and (max-width: 600px) {
  .admin-analytics-subheader {
    font-size: 16px;
  }
  .admin-analytics-class-subheader {
    font-size: 14px;
  }
}
