.ql-editor blockquote {
    padding: .1em 2rem;
    border-left: 8px solid rgb(65, 148, 136);
    line-height: 1.6;
    position: relative;
    background: #fafafa;
}

.tooltip {
    position: relative;
    display: inline-block;
    background: antiquewhite;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 95%;
    cursor: help;
}
  
/* Tooltip text */
.tooltip > span {
    visibility: hidden;
    width: max-content;
    max-width: 500px;
    font-size: 16px;
    background-color: #3791d4;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 1;
}

.tooltip[flow^="left"] > span {
    left: 0;
}
.tooltip[flow^="right"] > span {
    right: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover > span {
    visibility: visible;
}
