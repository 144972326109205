/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

.landing_page *,
.landing_page ::before,
.landing_page ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.landing_page ::before,
.landing_page ::after {
  --tw-content: '';
}

.landing_page html,
.landing_page :host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ProximaNova, sans-serif;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

.landing_page body {
  margin: 0;
  line-height: inherit;
}

.landing_page hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

.landing_page abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.landing_page h1,
.landing_page h2,
.landing_page h3,
.landing_page h4,
.landing_page h5,
.landing_page h6 {
  font-size: inherit;
  font-weight: inherit;
}

.landing_page a {
  color: inherit;
  text-decoration: inherit;
}

.landing_page b,
.landing_page strong {
  font-weight: bolder;
}

.landing_page code,
.landing_page kbd,
.landing_page samp,
.landing_page pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}

.landing_page small {
  font-size: 80%;
}

.landing_page sub,
.landing_page sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.landing_page sub {
  bottom: -0.25em;
}

.landing_page sup {
  top: -0.5em;
}

.landing_page table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.landing_page button,
.landing_page input,
.landing_page optgroup,
.landing_page select,
.landing_page textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.landing_page button,
.landing_page select {
  text-transform: none;
}

.landing_page button,
.landing_page input:where([type='button']),
.landing_page input:where([type='reset']),
.landing_page input:where([type='submit']) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.landing_page :-moz-focusring {
  outline: auto;
}

.landing_page :-moz-ui-invalid {
  box-shadow: none;
}

.landing_page progress {
  vertical-align: baseline;
}

.landing_page ::-webkit-inner-spin-button,
.landing_page ::-webkit-outer-spin-button {
  height: auto;
}

.landing_page [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.landing_page ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.landing_page ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.landing_page summary {
  display: list-item;
}

.landing_page blockquote,
.landing_page dl,
.landing_page dd,
.landing_page h1,
.landing_page h2,
.landing_page h3,
.landing_page h4,
.landing_page h5,
.landing_page h6,
.landing_page hr,
.landing_page figure,
.landing_page p,
.landing_page pre {
  margin: 0;
}

.landing_page fieldset {
  margin: 0;
  padding: 0;
}

.landing_page legend {
  padding: 0;
}

.landing_page ol,
.landing_page ul,
.landing_page menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.landing_page dialog {
  padding: 0;
}

.landing_page textarea {
  resize: vertical;
}

.landing_page input::-moz-placeholder,
.landing_page textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

.landing_page input::placeholder,
.landing_page textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.landing_page button,
.landing_page [role="button"] {
  cursor: pointer;
}

.landing_page :disabled {
  cursor: default;
}

.landing_page img,
.landing_page svg,
.landing_page video,
.landing_page canvas,
.landing_page audio,
.landing_page iframe,
.landing_page embed,
.landing_page object {
  display: block;
  vertical-align: middle;
}

.landing_page img,
.landing_page video {
  max-width: 100%;
  height: auto;
}

.landing_page [hidden] {
  display: none;
}

/* width */

.landing_page ::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  overflow: hidden;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.landing_page ::-webkit-scrollbar-track {
  background-color: rgb(15 23 42 / 0.1);
}

.landing_page ::-webkit-scrollbar-thumb {
  background-color: rgb(15 23 42 / 0.2);
}

.landing_page ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(15 23 42 / 0.3);
}

.landing_page html,
.landing_page body {
  height: 100%;
  width: 100%;
  font-family: "ProximaNova";
}

.landing_page *,
.landing_page ::before,
.landing_page ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.landing_page ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.landing_page .e-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.landing_page .e-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.landing_page .e-prose :where(p):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where([class~="lead"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.landing_page .e-prose :where(a):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.landing_page .e-prose :where(strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.landing_page .e-prose :where(a strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(blockquote strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(thead th strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(ol):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(ol[type="A"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.landing_page .e-prose :where(ol[type="a"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.landing_page .e-prose :where(ol[type="A" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.landing_page .e-prose :where(ol[type="a" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.landing_page .e-prose :where(ol[type="I"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-roman;
}

.landing_page .e-prose :where(ol[type="i"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-roman;
}

.landing_page .e-prose :where(ol[type="I" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: upper-roman;
}

.landing_page .e-prose :where(ol[type="i" s]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: lower-roman;
}

.landing_page .e-prose :where(ol[type="1"]):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: decimal;
}

.landing_page .e-prose :where(ul):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(ol > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.landing_page .e-prose :where(ul > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.landing_page .e-prose :where(dt):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}

.landing_page .e-prose :where(hr):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.landing_page .e-prose :where(blockquote):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-inline-start-width: 0.25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "\201C" "\201D" "\2018" "\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
}

.landing_page .e-prose :where(blockquote p:first-of-type):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: open-quote;
}

.landing_page .e-prose :where(blockquote p:last-of-type):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: close-quote;
}

.landing_page .e-prose :where(h1):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.landing_page .e-prose :where(h1 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}

.landing_page .e-prose :where(h2):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.landing_page .e-prose :where(h2 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}

.landing_page .e-prose :where(h3):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.landing_page .e-prose :where(h3 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.landing_page .e-prose :where(h4):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.landing_page .e-prose :where(h4 strong):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.landing_page .e-prose :where(img):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(picture):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(video):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(kbd):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: "`";
}

.landing_page .e-prose :where(code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: "`";
}

.landing_page .e-prose :where(a code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(h1 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(h2 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}

.landing_page .e-prose :where(h3 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}

.landing_page .e-prose :where(h4 code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(blockquote code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(thead th code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: inherit;
}

.landing_page .e-prose :where(pre):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-inline-start: 1.1428571em;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::before {
  content: none;
}

.landing_page .e-prose :where(pre code):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *))::after {
  content: none;
}

.landing_page .e-prose :where(table):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.landing_page .e-prose :where(thead):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.landing_page .e-prose :where(thead th):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

.landing_page .e-prose :where(tbody tr):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.landing_page .e-prose :where(tbody tr:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-bottom-width: 0;
}

.landing_page .e-prose :where(tbody td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  vertical-align: baseline;
}

.landing_page .e-prose :where(tfoot):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.landing_page .e-prose :where(tfoot td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  vertical-align: top;
}

.landing_page .e-prose :where(th, td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  text-align: start;
}

.landing_page .e-prose :where(figure > *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.landing_page .e-prose :where(figcaption):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.landing_page .e-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.landing_page .e-prose :where(picture > img):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.landing_page .e-prose :where(li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.landing_page .e-prose :where(ol > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(ul > li):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-start: 0.375em;
}

.landing_page .e-prose :where(.e-prose > ul > li p):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.landing_page .e-prose :where(.e-prose > ul > li > p:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ul > li > p:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ol > li > p:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
}

.landing_page .e-prose :where(.e-prose > ol > li > p:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.landing_page .e-prose :where(dl):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.landing_page .e-prose :where(dd):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0.5em;
  padding-inline-start: 1.625em;
}

.landing_page .e-prose :where(hr + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h2 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h3 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(h4 + *):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(thead th:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-start: 0;
}

.landing_page .e-prose :where(thead th:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-end: 0;
}

.landing_page .e-prose :where(tbody td, tfoot td):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-top: 0.5714286em;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

.landing_page .e-prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-start: 0;
}

.landing_page .e-prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  padding-inline-end: 0;
}

.landing_page .e-prose :where(figure):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.landing_page .e-prose :where(.e-prose > :first-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-top: 0;
}

.landing_page .e-prose :where(.e-prose > :last-child):not(:where([class~="e-not-prose"], [class~="e-not-prose"] *)) {
  margin-bottom: 0;
}

.landing_page .e-container {
  max-width: 80rem;
}

.landing_page .e-font-logo {
  font-family: "Carter One";
}

.landing_page .e-font-roboto {
  font-family: "Roboto";
}

.landing_page .e-btn-xs {
  height: 2rem !important;
}

.landing_page .e-btn {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.landing_page .e-btn-lg {
  height: 3.5rem;
}

.landing_page .e-btn-primary {
  background: linear-gradient(to top, #0484FF, #4FA7F7);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-btn-primary:hover {
  background: linear-gradient(to top right, #0484FF, #4FA7F7);
}

.landing_page .e-btn-light {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-btn-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.landing_page .e-btn-secondary {
  background-image: linear-gradient(to right, #FFA602, #FFCE70);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-btn-secondary:hover {
  background-image: linear-gradient(to top right, #FFA602, #FFCE70);
}

.landing_page .e-bg-custom-gradient {
  background: linear-gradient(to bottom right, #FFFCF099 0%, #FFFCF099 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) 100%);
}

.landing_page .e-bg-custom-gradient-primary {
  background: linear-gradient(to bottom right, #117EE410 0%, #117EE410 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) 100%);
}

.landing_page .e-bg-gradient-sky {
  background-image: linear-gradient(to top right, #e0f2fe, #f0f9ff);
}

.landing_page .e-bg-gradient-amber {
  background-image: linear-gradient(to top right, #fef3c7, #fffbeb);
}

.landing_page .e-bg-gradient-purple {
  background-image: linear-gradient(to top right, #f3e8ff, #faf5ff);
}

.landing_page .e-bg-gradient-rose {
  background-image: linear-gradient(to top right, #ffe4e6, #fff1f2);
}

.landing_page .e-card {
  perspective: 1000px;
}

.landing_page .e-card-content {
  transform-style: preserve-3d;
}

.landing_page .e-card:hover .landing_page .e-card-content {
  transform: rotateY(15deg) rotateX(10deg);
}

.landing_page .e-titlemark {
  position: relative;
}

.landing_page .e-titlemark::before {
  position: absolute;
  right: -1rem;
  top: -0.125rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  --tw-rotate: 75deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url('../../assets/images/landing/titlemark.svg');
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.landing_page .e-graph-paper::before {
  content: '';
  z-index: -1;
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.landing_page .e-swiper-prev,
.landing_page .e-swiper-next {
  display: flex;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #0484FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(4 132 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #4FA7F7 var(--tw-gradient-to-position);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-swiper-prev:hover,
.landing_page .e-swiper-next:hover {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.landing_page .e-swiper-prev,
.landing_page .e-swiper-next {
  box-shadow: inset 0px 0px 0px 2px transparent;
}

.landing_page .e-swiper-prev.swiper-button-disabled,
.landing_page .e-swiper-next.swiper-button-disabled {
  cursor: not-allowed;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
  color: inherit;
  box-shadow: inset 0px 0px 0px 2px #e5e7eb;
}

.landing_page .e-accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
}

.landing_page .e-accordion-content.e-show {
  opacity: 1;
}

.landing_page .e-modal {
  visibility: hidden;
  position: fixed;
  inset: 0px;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  background-color: #0006;
}

.landing_page .e-modal.e-show {
  visibility: visible;
  opacity: 1;
}

.landing_page .e-modal .e-modal_card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-direction: column;
  overflow: auto;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  max-height: calc(100vh - 80px);
  border: .5px solid #e1e6f0;
}

.landing_page .e-modal.e-show .e-modal_card {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .ui.selection.dropdown {
  position: relative;
}

.landing_page .ui.selection.dropdown>.text {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;
  transition: transform 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .ui.selection.dropdown>.text:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.landing_page .ui.selection.dropdown>.text {
  cursor: pointer;
}

.landing_page .ui.selection.dropdown>.menu {
  position: absolute;
  right: 0px;
  margin-top: 0.25rem;
  display: none;
  width: 14rem;
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .ui.selection.dropdown>.menu.visible {
  display: flex;
  flex-direction: column;
}

.landing_page .ui.selection.dropdown>.menu .item {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .ui.selection.dropdown>.menu .item:hover {
  background-color: rgb(17 126 228 / 0.05);
}

.landing_page .ui.selection.dropdown .item.selected {
  position: relative;
}

.landing_page .ui.selection.dropdown .item.selected::before {
  position: absolute;
  right: 1rem;
  top: 50%;
  display: flex;
  height: 1rem;
  width: 1rem;
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='rgba(17,126,228,1)'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
}

.landing_page .e-fixed {
  position: fixed;
}

.landing_page .e-absolute {
  position: absolute;
}

.landing_page .e-relative {
  position: relative;
}

.landing_page .e-sticky {
  position: sticky;
}

.landing_page .e-inset-x-0 {
  left: 0px;
  right: 0px;
}

.landing_page .e-bottom-2 {
  bottom: 0.5rem;
}

.landing_page .e-left-0 {
  left: 0px;
}

.landing_page .e-right-0 {
  right: 0px;
}

.landing_page .e-right-2 {
  right: 0.5rem;
}

.landing_page .e-right-4 {
  right: 1rem;
}

.landing_page .e-right-px {
  right: 1px;
}

.landing_page .e-top-0 {
  top: 0px;
}

.landing_page .e-top-1\/2 {
  top: 50%;
}

.landing_page .e-top-4 {
  top: 1rem;
}

.landing_page .e-top-px {
  top: 1px;
}

.landing_page .e-z-10 {
  z-index: 10;
}

.landing_page .e-z-50 {
  z-index: 50;
}

.landing_page .e-col-span-2 {
  grid-column: span 2 / span 2;
}

.landing_page .e-col-span-full {
  grid-column: 1 / -1;
}

.landing_page .e-m-auto {
  margin: auto;
}

.landing_page .e-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.landing_page .e-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.landing_page .\!e-mb-4 {
  margin-bottom: 1rem !important;
}

.landing_page .-e-mt-1 {
  margin-top: -0.25rem;
}

.landing_page .e-mb-0 {
  margin-bottom: 0px;
}

.landing_page .e-mb-20 {
  margin-bottom: 5rem;
}

.landing_page .e-mb-4 {
  margin-bottom: 1rem;
}

.landing_page .e-mb-40 {
  margin-bottom: 10rem;
}

.landing_page .e-mb-6 {
  margin-bottom: 1.5rem;
}

.landing_page .e-mb-8 {
  margin-bottom: 2rem;
}

.landing_page .e-ml-1 {
  margin-left: 0.25rem;
}

.landing_page .e-ml-auto {
  margin-left: auto;
}

.landing_page .e-mr-2 {
  margin-right: 0.5rem;
}

.landing_page .e-mt-1 {
  margin-top: 0.25rem;
}

.landing_page .e-mt-10 {
  margin-top: 2.5rem;
}

.landing_page .e-mt-2 {
  margin-top: 0.5rem;
}

.landing_page .e-mt-4 {
  margin-top: 1rem;
}

.landing_page .e-flex {
  display: flex;
}

.landing_page .e-grid {
  display: grid;
}

.landing_page .e-hidden {
  display: none;
}

.landing_page .e-aspect-square {
  aspect-ratio: 1 / 1;
}

.landing_page .e-aspect-video {
  aspect-ratio: 16 / 9;
}

.landing_page .e-h-0\.5 {
  height: 0.125rem;
}

.landing_page .e-h-10 {
  height: 2.5rem;
}

.landing_page .e-h-12 {
  height: 3rem;
}

.landing_page .e-h-14 {
  height: 3.5rem;
}

.landing_page .e-h-16 {
  height: 4rem;
}

.landing_page .e-h-20 {
  height: 5rem;
}

.landing_page .e-h-3 {
  height: 0.75rem;
}

.landing_page .e-h-5 {
  height: 1.25rem;
}

.landing_page .e-h-6 {
  height: 1.5rem;
}

.landing_page .e-h-72 {
  height: 18rem;
}

.landing_page .e-h-\[1\.125rem\] {
  height: 1.125rem;
}

.landing_page .e-h-full {
  height: 100%;
}

.landing_page .e-h-screen {
  height: 100vh;
}

.landing_page .e-w-1\/3 {
  width: 33.333333%;
}

.landing_page .e-w-12 {
  width: 3rem;
}

.landing_page .e-w-14 {
  width: 3.5rem;
}

.landing_page .e-w-16 {
  width: 4rem;
}

.landing_page .e-w-2\/3 {
  width: 66.666667%;
}

.landing_page .e-w-20 {
  width: 5rem;
}

.landing_page .e-w-28 {
  width: 7rem;
}

.landing_page .e-w-3\/4 {
  width: 75%;
}

.landing_page .e-w-36 {
  width: 9rem;
}

.landing_page .e-w-4 {
  width: 1rem;
}

.landing_page .e-w-44 {
  width: 11rem;
}

.landing_page .e-w-48 {
  width: 12rem;
}

.landing_page .e-w-5 {
  width: 1.25rem;
}

.landing_page .e-w-5\/6 {
  width: 83.333333%;
}

.landing_page .e-w-56 {
  width: 14rem;
}

.landing_page .e-w-8 {
  width: 2rem;
}

.landing_page .e-w-80 {
  width: 20rem;
}

.landing_page .e-w-\[1\.125rem\] {
  width: 1.125rem;
}

.landing_page .e-w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.landing_page .e-w-full {
  width: 100%;
}

.landing_page .e-max-w-3xl {
  max-width: 48rem;
}

.landing_page .e-max-w-md {
  max-width: 28rem;
}

.landing_page .e-flex-shrink-0 {
  flex-shrink: 0;
}

.landing_page .-e-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .-e-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-translate-x-10 {
  --tw-translate-x: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-translate-x-40 {
  --tw-translate-x: 10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes e-pulse {
  50% {
    opacity: .5;
  }
}

.landing_page .e-animate-pulse {
  animation: e-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.landing_page .e-cursor-pointer {
  cursor: pointer;
}

.landing_page .e-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.landing_page .e-resize-none {
  resize: none;
}

.landing_page .e-columns-3 {
  -moz-columns: 3;
  columns: 3;
}

.landing_page .e-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.landing_page .e-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.landing_page .e-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.landing_page .e-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.landing_page .e-flex-col {
  flex-direction: column;
}

.landing_page .e-flex-wrap {
  flex-wrap: wrap;
}

.landing_page .e-items-start {
  align-items: flex-start;
}

.landing_page .e-items-end {
  align-items: flex-end;
}

.landing_page .e-items-center {
  align-items: center;
}

.landing_page .e-justify-end {
  justify-content: flex-end;
}

.landing_page .e-justify-center {
  justify-content: center;
}

.landing_page .e-justify-between {
  justify-content: space-between;
}

.landing_page .e-gap-10 {
  gap: 2.5rem;
}

.landing_page .e-gap-2 {
  gap: 0.5rem;
}

.landing_page .e-gap-4 {
  gap: 1rem;
}

.landing_page .e-gap-6 {
  gap: 1.5rem;
}

.landing_page .e-gap-x-10 {
  -moz-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.landing_page .e-gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.landing_page .e-gap-y-2 {
  row-gap: 0.5rem;
}

.landing_page .e-gap-y-3 {
  row-gap: 0.75rem;
}

.landing_page .e-space-x-1\.5> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-10> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-2> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-3> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-4> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-x-6> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.landing_page .e-space-y-1> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-10> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-2> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-3> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.landing_page .e-space-y-6> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.landing_page .e-overflow-auto {
  overflow: auto;
}

.landing_page .e-overflow-hidden {
  overflow: hidden;
}

.landing_page .e-rounded {
  border-radius: 0.25rem;
}

.landing_page .e-rounded-2xl {
  border-radius: 1rem;
}

.landing_page .e-rounded-3xl {
  border-radius: 1.5rem;
}

.landing_page .e-rounded-full {
  border-radius: 9999px;
}

.landing_page .e-rounded-lg {
  border-radius: 0.5rem;
}

.landing_page .e-rounded-md {
  border-radius: 0.375rem;
}

.landing_page .e-rounded-sm {
  border-radius: 0.125rem;
}

.landing_page .e-rounded-xl {
  border-radius: 0.75rem;
}

.landing_page .e-border {
  border-width: 1px;
}

.landing_page .e-border-2 {
  border-width: 2px;
}

.landing_page .e-border-b {
  border-bottom-width: 1px;
}

.landing_page .e-border-t {
  border-top-width: 1px;
}

.landing_page .e-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.landing_page .e-border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.landing_page .e-border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.landing_page .e-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .e-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.landing_page .e-bg-secondary\/20 {
  background-color: rgb(255 165 1 / 0.2);
}

.landing_page .e-bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.landing_page .e-bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.landing_page .e-bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.landing_page .e-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.landing_page .e-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.landing_page .e-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.landing_page .e-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.landing_page .e-from-amber-100 {
  --tw-gradient-from: #fef3c7 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-amber-400 {
  --tw-gradient-from: #fbbf24 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-purple-100 {
  --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-purple-400 {
  --tw-gradient-from: #c084fc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-rose-100 {
  --tw-gradient-from: #ffe4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-rose-400 {
  --tw-gradient-from: #fb7185 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-sky-100 {
  --tw-gradient-from: #e0f2fe var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-from-sky-400 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.landing_page .e-to-amber-200 {
  --tw-gradient-to: #fde68a var(--tw-gradient-to-position);
}

.landing_page .e-to-amber-50 {
  --tw-gradient-to: #fffbeb var(--tw-gradient-to-position);
}

.landing_page .e-to-purple-200 {
  --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.landing_page .e-to-purple-50 {
  --tw-gradient-to: #faf5ff var(--tw-gradient-to-position);
}

.landing_page .e-to-rose-200 {
  --tw-gradient-to: #fecdd3 var(--tw-gradient-to-position);
}

.landing_page .e-to-rose-50 {
  --tw-gradient-to: #fff1f2 var(--tw-gradient-to-position);
}

.landing_page .e-to-sky-200 {
  --tw-gradient-to: #bae6fd var(--tw-gradient-to-position);
}

.landing_page .e-to-sky-50 {
  --tw-gradient-to: #f0f9ff var(--tw-gradient-to-position);
}

.landing_page .e-object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.landing_page .e-object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.landing_page .e-p-10 {
  padding: 2.5rem;
}

.landing_page .e-p-4 {
  padding: 1rem;
}

.landing_page .e-p-6 {
  padding: 1.5rem;
}

.landing_page .e-p-7 {
  padding: 1.75rem;
}

.landing_page .e-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.landing_page .e-px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.landing_page .e-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.landing_page .e-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.landing_page .e-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.landing_page .e-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.landing_page .e-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.landing_page .e-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.landing_page .e-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.landing_page .e-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.landing_page .e-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.landing_page .e-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.landing_page .e-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.landing_page .e-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.landing_page .e-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.landing_page .e-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.landing_page .e-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing_page .e-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.landing_page .e-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.landing_page .e-pb-16 {
  padding-bottom: 4rem;
}

.landing_page .e-pl-12 {
  padding-left: 3rem;
}

.landing_page .e-pr-20 {
  padding-right: 5rem;
}

.landing_page .e-pr-3 {
  padding-right: 0.75rem;
}

.landing_page .e-pt-0 {
  padding-top: 0px;
}

.landing_page .e-pt-36 {
  padding-top: 9rem;
}

.landing_page .e-pt-6 {
  padding-top: 1.5rem;
}

.landing_page .e-text-left {
  text-align: left;
}

.landing_page .e-text-center {
  text-align: center;
}

.landing_page .e-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.landing_page .e-text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.landing_page .e-text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.landing_page .e-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.landing_page .e-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.landing_page .e-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.landing_page .e-font-medium {
  font-weight: 500;
}

.landing_page .e-font-semibold {
  font-weight: 600;
}

.landing_page .e-leading-none {
  line-height: 1;
}

.landing_page .e-leading-tight {
  line-height: 1.25;
}

.landing_page .e-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.landing_page .e-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.landing_page .e-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.landing_page .e-text-primary {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

.landing_page .e-text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.landing_page .e-text-secondary {
  --tw-text-opacity: 1;
  color: rgb(255 165 1 / var(--tw-text-opacity));
}

.landing_page .e-text-secondary\/20 {
  color: rgb(255 165 1 / 0.2);
}

.landing_page .e-text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.landing_page .e-text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.landing_page .e-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.landing_page .e-text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.landing_page .e-opacity-0 {
  opacity: 0;
}

.landing_page .e-opacity-100 {
  opacity: 1;
}

.landing_page .e-opacity-5 {
  opacity: 0.05;
}

.landing_page .e-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-\[0_15px_20px_-25px_rgba\(0\,0\,0\,\.35\)\] {
  --tw-shadow: 0 15px 20px -25px rgba(0, 0, 0, .35);
  --tw-shadow-colored: 0 15px 20px -25px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.landing_page .e-shadow-gray-400\/5 {
  --tw-shadow-color: rgb(156 163 175 / 0.05);
  --tw-shadow: var(--tw-shadow-colored);
}

.landing_page .e-shadow-secondary\/20 {
  --tw-shadow-color: rgb(255 165 1 / 0.2);
  --tw-shadow: var(--tw-shadow-colored);
}

.landing_page .e-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.landing_page .e-drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.landing_page .e-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .e-duration-300 {
  transition-duration: 300ms;
}

.landing_page .e-duration-500 {
  transition-duration: 500ms;
}

@layer utils {
  @font-face {
    font-family: "Carter One";

    src: url("../../assets/fonts/CarterOne-Regular.woff2") format("woff2");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: "Roboto";

    src: url("../../assets/fonts/Roboto-Regular.woff2") format("woff2");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url("../../assets/fonts/ProximaNova-Light.woff") format("woff");

    font-weight: 300;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url("../../assets/fonts/ProximaNova-Regular.woff") format("woff");

    font-weight: 400;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url("../../assets/fonts/ProximaNova-SemiBold.woff") format("woff");

    font-weight: 500;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url("../../assets/fonts/ProximaNova-Bold.woff") format("woff");

    font-weight: 600;

    font-style: normal;

    font-display: swap;
  }

  @font-face {
    font-family: 'ProximaNova';

    src: url("../../assets/fonts/ProximaNova-Extrabld.woff") format("woff");

    font-weight: 700;

    font-style: normal;

    font-display: swap;
  }
}

@media (max-width: 575px) {
  .landing_page .ui.selection.dropdown>.sm\:data-\[show\=true\]\:visible[data-show="true"].menu {
    display: flex;
    flex-direction: column;
  }
}

.landing_page .before\:e-absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.landing_page .before\:-e-top-1\.5::before {
  content: var(--tw-content);
  top: -0.375rem;
}

.landing_page .before\:e-left-0::before {
  content: var(--tw-content);
  left: 0px;
}

.landing_page .before\:e-mt-0\.5::before {
  content: var(--tw-content);
  margin-top: 0.125rem;
}

.landing_page .before\:e-h-0\.5::before {
  content: var(--tw-content);
  height: 0.125rem;
}

.landing_page .before\:e-w-4::before {
  content: var(--tw-content);
  width: 1rem;
}

.landing_page .before\:e-flex-1::before {
  content: var(--tw-content);
  flex: 1 1 0%;
}

.landing_page .before\:e-border-t::before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.landing_page .before\:e-border-gray-200::before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.landing_page .before\:e-bg-black::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .before\:e-transition::before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .after\:e-absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.landing_page .after\:-e-bottom-1\.5::after {
  content: var(--tw-content);
  bottom: -0.375rem;
}

.landing_page .after\:e-left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.landing_page .after\:e-mt-0\.5::after {
  content: var(--tw-content);
  margin-top: 0.125rem;
}

.landing_page .after\:e-h-0\.5::after {
  content: var(--tw-content);
  height: 0.125rem;
}

.landing_page .after\:e-w-2\.5::after {
  content: var(--tw-content);
  width: 0.625rem;
}

.landing_page .after\:e-flex-1::after {
  content: var(--tw-content);
  flex: 1 1 0%;
}

.landing_page .after\:e-border-t::after {
  content: var(--tw-content);
  border-top-width: 1px;
}

.landing_page .after\:e-border-gray-200::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.landing_page .after\:e-bg-black::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.landing_page .after\:e-transition::after {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.landing_page .hover\:e-bg-primary\/5:hover {
  background-color: rgb(17 126 228 / 0.05);
}

.landing_page .hover\:e-text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

.landing_page .focus\:e-border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(17 126 228 / var(--tw-border-opacity));
}

.landing_page .e-group:hover .group-hover\:e-translate-x-1\.5 {
  --tw-translate-x: 0.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:checked~.peer-checked\:e-border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(17 126 228 / var(--tw-border-opacity));
}

.landing_page .e-peer:checked~.peer-checked\:e-bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(17 126 228 / var(--tw-bg-opacity));
}

.landing_page .e-peer:checked~.peer-checked\:e-bg-transparent {
  background-color: transparent;
}

.landing_page .e-peer:checked~.peer-checked\:before\:e-top-0::before {
  content: var(--tw-content);
  top: 0px;
}

.landing_page .e-peer:checked~.peer-checked\:before\:-e-rotate-45::before {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-top-0::after {
  content: var(--tw-content);
  top: 0px;
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-w-4::after {
  content: var(--tw-content);
  width: 1rem;
}

.landing_page .e-peer:checked~.peer-checked\:after\:e-rotate-45::after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.landing_page .e-peer:focus~.peer-focus\:e-text-primary {
  --tw-text-opacity: 1;
  color: rgb(17 126 228 / var(--tw-text-opacity));
}

@media (max-width: 1279px) {
  .landing_page .xl\:e-gap-6 {
    gap: 1.5rem;
  }

  .landing_page .xl\:e-space-y-6> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
}

@media (max-width: 1023px) {
  .landing_page .lg\:e-col-span-full {
    grid-column: 1 / -1;
  }

  .landing_page .lg\:e-hidden {
    display: none;
  }

  .landing_page .lg\:e-h-full {
    height: 100%;
  }

  .landing_page .lg\:e-w-full {
    width: 100%;
  }

  .landing_page .lg\:e-translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .lg\:e-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .lg\:e-columns-2 {
    -moz-columns: 2;
    columns: 2;
  }

  .landing_page .lg\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .lg\:e-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .landing_page .lg\:e-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .landing_page .md\:e-absolute {
    position: absolute;
  }

  .landing_page .md\:e-bottom-0 {
    bottom: 0px;
  }

  .landing_page .md\:e-right-0 {
    right: 0px;
  }

  .landing_page .md\:e-z-10 {
    z-index: 10;
  }

  .landing_page .md\:e-origin-bottom-right {
    transform-origin: bottom right;
  }

  .landing_page .md\:e-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .md\:e-columns-1 {
    -moz-columns: 1;
    columns: 1;
  }

  .landing_page .md\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .md\:e-flex-col {
    flex-direction: column;
  }

  .landing_page .md\:e-gap-4 {
    gap: 1rem;
  }

  .landing_page .md\:e-p-6 {
    padding: 1.5rem;
  }

  .landing_page .md\:e-pr-20 {
    padding-right: 5rem;
  }

  .landing_page .md\:e-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .landing_page .md\:e-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (max-width: 575px) {
  .landing_page .sm\:e-invisible {
    visibility: hidden;
  }

  .landing_page .sm\:e-col-span-full {
    grid-column: 1 / -1;
  }

  .landing_page .sm\:e-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .landing_page .sm\:\!e-mr-auto {
    margin-right: auto !important;
  }

  .landing_page .sm\:e-mb-0 {
    margin-bottom: 0px;
  }

  .landing_page .sm\:e-ml-0 {
    margin-left: 0px;
  }

  .landing_page .sm\:e-flex {
    display: flex;
  }

  .landing_page .sm\:e-hidden {
    display: none;
  }

  .landing_page .sm\:e-h-auto {
    height: auto;
  }

  .landing_page .sm\:e-h-fit {
    height: -moz-fit-content;
    height: fit-content;
  }

  .landing_page .sm\:e-max-h-0 {
    max-height: 0px;
  }

  .landing_page .sm\:e-w-full {
    width: 100%;
  }

  .landing_page .sm\:-e-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e--translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e-scale-35 {
    --tw-scale-x: 0.35;
    --tw-scale-y: 0.35;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .landing_page .sm\:e-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .landing_page .sm\:e-gap-6 {
    gap: 1.5rem;
  }

  .landing_page .sm\:e-space-x-4> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .landing_page .sm\:e-border-b {
    border-bottom-width: 1px;
  }

  .landing_page .sm\:e-bg-none {
    background-image: none;
  }

  .landing_page .sm\:e-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .landing_page .sm\:e-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .landing_page .sm\:e-text-inherit {
    color: inherit;
  }

  .landing_page .sm\:e-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .landing_page .e-peer:checked~.sm\:peer-checked\:e-mb-4 {
    margin-bottom: 1rem;
  }

  .landing_page .e-peer:checked~.sm\:peer-checked\:e-flex {
    display: flex;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-visible[data-show="true"] {
    visibility: visible;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-mb-2[data-show="true"] {
    margin-bottom: 0.5rem;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-max-h-14[data-show="true"] {
    max-height: 3.5rem;
  }

  .landing_page .sm\:data-\[show\=true\]\:e-translate-y-0[data-show="true"] {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landing_page .sm\:data-\[show\=true\]\:e-pb-2[data-show="true"] {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 447px) {
  .landing_page .xs\:e-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.landing_page .\[\&\>li\]\:e-flex>li {
  display: flex;
}

.landing_page .\[\&\>li\]\:e-whitespace-nowrap>li {
  white-space: nowrap;
}

.landing_page .\[\&\>li\]\:before\:e-mr-2\.5>li::before {
  content: var(--tw-content);
  margin-right: 0.625rem;
}

.landing_page .\[\&\>li\]\:before\:e-inline-block>li::before {
  content: var(--tw-content);
  display: inline-block;
}

.landing_page .\[\&\>li\]\:before\:e-h-6>li::before {
  content: var(--tw-content);
  height: 1.5rem;
}

.landing_page .\[\&\>li\]\:before\:e-w-6>li::before {
  content: var(--tw-content);
  width: 1.5rem;
}

.landing_page .\[\&\>li\]\:before\:e-bg-\[url\(\.\.\/images\/checkmark\.svg\)\]>li::before {
  content: var(--tw-content);
  background-image: url('../../assets/images/landing/checkmark.svg');
}

/* Common */
.landing-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 54px 40px;
}

.landing-modal-cross {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}

.landing-modal-cross:hover {
  opacity: 1;
}

.landing-modal-header {
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}

.landing-modal-header>span {
  border-bottom: 1px solid #000;
}

.landing-modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  font-weight: 400;
}

.landing-modal-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-modal-form-note {
  font-style: italic;
}

.landing-modal-form-message {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  width: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.landing-modal-form-message.success {
  color: green;
}

.landing-modal-form-message.error {
  color: red;
}

.landing-modal-form-input {
  width: 100%;
  height: 48px;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0 1px 0 0 #000;
  border: none;
  outline: none;
}

.landing-modal-form-checkbox>label {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.landing-modal-form-checkbox input,
.landing-modal-form-checkbox label {
  cursor: pointer;
}

.landing-modal-form-controls {
  display: flex;
  justify-content: flex-end;
  font-size: 32px;
  font-weight: 400;
}

.landing-modal-form-controls>input {
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
}

.landing-modal-form-controls>input:disabled {
  cursor: not-allowed;
  color: rgba(16, 16, 16, 0.3);
}

.landing-modal-login-kundelik {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  padding: 8px 12px;
  background-color: gainsboro;
  border-radius: 12px;
  font-family: sans-serif;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.8;
}

.landing-modal-login-kundelik:hover {
  opacity: 1;
}

.landing-modal-login-kundelik>img {
  width: 96px;
}

/* Authentication (login/forgot password) */
.landing-modal-form-toggle-login>span {
  cursor: pointer;
}

.landing-modal-form-toggle-login>span {
  color: #324f7e;
}

.landing-modal-form-toggle-login>span:hover {
  border-bottom: 1px solid #324f7e;
}

/* Authentication (registration) */

/* Other modals */
.landing-modal-about.text,
.landing-modal-manual.text {
  line-height: 24px;
  font-size: 16px;
  text-align: justify;
}

.landing-modal-terms {
  text-align: justify;
}

.landing-modal-about.contacts__list {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.landing-modal-about.contacts__item {
  line-height: 1;
  font-size: 18px;
}

.landing-modal-manual ol {
  margin-top: 0;
  padding-left: 2rem;
}

@media only screen and (max-width: 480px) {
  .landing-modal {
    padding: 40px 32px;
  }

  .landing-modal-cross {
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
  }

  .landing-modal-header {
    font-size: 28px;
    line-height: 28px;
  }

  .landing-modal-form-input {
    height: 36px;
    line-height: 18px;
    font-size: 16px;
  }

  .landing-modal-form-controls {
    font-size: 24px;
  }
}

@media only screen and (max-width: 400px) {
  .landing-modal {
    padding: 36px 24px;
    font-size: 14px;
  }

  .landing-modal-cross {
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
  }

  .landing-modal-header {
    font-size: 22px;
    line-height: 22px;
  }

  .landing-modal-form-input {
    height: 32px;
    font-size: 14px;
    line-height: 14px;
  }

  .landing-modal-form-controls {
    font-size: 20px;
  }

  .landing-modal-about .contacts__list {
    gap: 16px;
  }

  .landing-modal-about .contacts__item {
    font-size: 14px;
  }
}